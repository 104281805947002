import { default as MidasMembership } from "@Assets/homepage/midas-membership.png";
import { TextWithLeftImageComponent } from "@Libraries/components/text-image/TextWithLeftImage.component";

export const MembershipSection = () => {
  return (
    <TextWithLeftImageComponent
      image={<img src={MidasMembership} alt="MidasCuan - Midas Membership" />}
      textChildren={
        <>
          <div className="responsive-header-title text-white font-extrabold">
            MIDAS
            <div className="color-brand-yellow">Membership</div>
          </div>
          <div className="text-xl text-white mt-6">
            Investasi terbaik, adalah berinvestasi dengan memahami 'apa' dan
            'kenapa' anda membeli sebuah saham.
          </div>
          <div className="text-xl text-white mt-6">
            Di program ini, anda akan belajar memiliki{" "}
            <strong>
              mindset, kemampuan analisa, dan framework memilih saham terbaik
            </strong>{" "}
            untuk diinvestasikan sehingga
            <strong>memaksimalkan hasil dari investasi anda!</strong>
          </div>
        </>
      }
      showButton={false}
    />
  );
};
