import { StockModel } from "@Features/dashboard/data/stock.model";
import { GetStockDetailUsecase } from "@Features/dashboard/domain/GetStockDetail.usecase";
import { GetStockListUsecase } from "@Features/dashboard/domain/GetStockList.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Zoom from "chartjs-plugin-zoom";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState } from "react";

import {
  ChartProps,
  MidasChart,
  MidasChartType,
} from "@Features/dashboard/presentation/components/Chart.component";
import { TableComponent, TableProps } from "../components/Table.component";
import { JarvisCalculationComponent } from "../components/JarvisCalculation.component";
import { Helmet } from "react-helmet";
import Select from "react-select";

export const JarvisSection = () => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Zoom,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const [isLoading, setIsLoading] = useState(true);
  const [listOfStocks, setListOfStocks] = useState<StockModel[]>([]);
  const [selectedParams, setSelectedParams] = useState({
    period: "quarterly",
    stock: "",
  });
  const [stockDetails, setStockDetails] = useState<any>(null);

  const defaultOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        color: "white",
        font: { weight: "bold", size: 16 },
      },
      legend: {
        labels: {
          color: "white",
          padding: 16,
          font: {
            size: 12,
          },
        },
      },
      datalabels: {
        display:false
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: "ctrl" as const,
          },
        },
        pan: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
      },
    },
    // scales: {
    //   y: {
    //     type: "linear" as const,
    //     display: true,
    //     position: "left" as const,
    //     grid: {
    //       drawOnChartArea: false,
    //     },
    //   },
    //   y1: {
    //     type: "linear" as const,
    //     display: true,
    //     position: "right" as const,
    //     grid: {
    //       drawOnChartArea: false,
    //     },
    //   },
    //   y2: {
    //     type: "linear" as const,
    //     display: true,
    //     position: "right" as const,
    //     grid: {
    //       drawOnChartArea: false,
    //     },
    //   },
    // },
  };

  useEffect(() => {
    async function fetchStocks() {
      try {
        let list = await GetStockListUsecase();
        setListOfStocks(list);
      } catch (e) { }
      setIsLoading(false);
    }

    fetchStocks();
  }, []);

  useEffect(() => {
    if (selectedParams.stock !== "") {
      setIsLoading(true);

      async function fetchDetails() {
        let detail = await GetStockDetailUsecase(
          selectedParams.period,
          selectedParams.stock
        );

        setStockDetails(detail);
        setIsLoading(false);
      }

      fetchDetails();
    }
  }, [selectedParams]);

  const onChangePeriod = (newPeriod: string) =>
    setSelectedParams({ ...selectedParams, period: newPeriod });

  const buildPeriodForm = () => (
    <div className="w-full flex flex-col md:flex-row gap-4 mt-6">
      <div className="my-auto text-white w-full md:w-1/4">Periode</div>
      <div className="w-full flex gap-12">
        <button onClick={() => onChangePeriod("quarterly")}>
          <input
            type={"radio"}
            checked={selectedParams.period.toLowerCase() === "quarterly"}
            onChange={() => { }}
          />{" "}
          Quarterly
        </button>
        <button onClick={() => onChangePeriod("annual")}>
          <input
            type={"radio"}
            checked={selectedParams.period.toLowerCase() === "annual"}
            onChange={() => { }}
          />{" "}
          Annual
        </button>
        <button onClick={() => onChangePeriod("ttm")}>
          <input
            type={"radio"}
            checked={selectedParams.period.toLowerCase() === "ttm"}
            onChange={() => { }}
          />{" "}
          TTM
        </button>
      </div>
    </div>
  );

  const getIncomeChartData = () => {
    let returnData = {
      labels: [] as string[],
      net: [] as any[],
      closing: [] as any[],
    };

    let fullChartData = stockDetails.full_graph;

    Object.keys(fullChartData).forEach((key) => {
      if (key !== "label") {
        returnData.labels.push(key);
        returnData.net.push(fullChartData[key]["Net Income"]);
        returnData.closing.push(fullChartData[key]["Closing Price"]);
      }
    });

    return returnData;
  };

  const getBankingIncomeWaterfallChartData = () => {
    let returnData = {
      labels: [] as string[],
      waterfall: [] as any[],
      waterfallData: [] as any[],
    };

    let chartData = stockDetails.banking_income.slice(1);

    let storedNumber = [] as any[];
    
    let firstNumber = 0 as number;
    
    let secondNumber = 0 as number;

    for (let i = 0; i < chartData.length; i++) {
      if ( i == 0 ){
        firstNumber = 0;
      } else {
        firstNumber = secondNumber;
      }

      // Start to handle data Tax & Minority that are not in the data
      if( i !== chartData.length - 1 ) {
        secondNumber = firstNumber + parseFloat(
          chartData[i][1]
          .replaceAll(",", "")
          .replaceAll(")", "")
          .replaceAll("(", "-")
        );
      } else {
        secondNumber = firstNumber - ( firstNumber- parseFloat(
          chartData[i][1]
          .replaceAll(",", "")
          .replaceAll(")", "")
          .replaceAll("(", "-")
        ) ) ;
      }
      // end
      
      let waterfallBase = parseFloat(
        chartData[i][1]
        .replaceAll(",", "")
        .replaceAll(")", "")
        .replaceAll("(", "-")
      );
      
      storedNumber.push([firstNumber,secondNumber]);
      
      returnData.labels.push(chartData[i][0]);
      returnData.waterfall.push(waterfallBase);
    }
    storedNumber.push([0,secondNumber]);

    returnData.labels.splice(returnData.waterfall.length-1,1,'Tax & Minority','Net Profit')
    returnData.waterfallData.push(storedNumber);
    return returnData;
  };

  const getBankingDevidenChartData = () => {
    let returnData = {
      labels: [] as string[],
      deviden: [] as any[],
    };

    let devidenChartData = stockDetails.banking_deviden;

    Object.keys(devidenChartData).forEach((key) => {
      if (key !== "label") {
        returnData.labels.push(key);
        returnData.deviden.push(devidenChartData[key]["Total_Dividend"]);
      }
    });

    return returnData;
  };

  const getBankingLoanDepositChartData = () => {
    let returnData = {
      labels: [] as string[],
      deposits: [] as any[],
      loans: [] as any[],
    };

    let chartData = stockDetails.banking_loan_deposit;

    if (chartData.length === 0) {
      return null;
    }
    
    (chartData[0] as string[]).forEach((item, index) => {
      returnData.labels.push(item);
    });
    returnData.labels.shift()

    for (let i = 0; i < returnData.labels.length; i++) {
      let deposit = parseFloat(
        chartData[2][i+1]
        .replaceAll(",", "")
        .replaceAll(")", "")
        .replaceAll("(", "-")
      );
      let loan = parseFloat(
        chartData[1][i+1]
        .replaceAll(",", "")
        .replaceAll(")", "")
        .replaceAll("(", "-")
      );
      returnData.deposits.push(deposit);
      returnData.loans.push(loan);
    }
    return returnData;
  };

  const getTotalIncomeAndProfitChartData = () => {
    let returnData = {
      labels: [] as string[],
      total: [] as any[],
      profit: [] as any[],
    };

    let chartData = stockDetails.income;

    if (!("Laba Bersih" in chartData) || !("Total Pendapatan" in chartData)) {
      return null;
    }

    let lastYear = 0;
    let currentYear = 0;

    (chartData.label as string[]).forEach((item, index) => {
      if (index !== 0) {
        let year = parseInt(item.split(" ")[1]);
        if (year) {
          if (lastYear === 0) {
            lastYear = year;
          }

          if (year === lastYear) {
            returnData.labels.push(item);
          } else if (currentYear !== year) {
            returnData.labels.push(item);
            currentYear = year;
          }
        }
      }
    });

    for (let i = 0; i < returnData.labels.length; i++) {
      let profit = parseFloat(
        chartData["Laba Bersih"][returnData.labels[i]]
          .replaceAll(" ", "")
          .replaceAll(",", "")
      );
      let total = parseFloat(
        chartData["Total Pendapatan"][returnData.labels[i]].replace(",", "")
      );

      returnData.profit.push(profit);
      returnData.total.push(total);
    }

    return returnData;
  };

  const getBalanceSheetChartData = () => {
    let returnData = {
      labels: [] as string[],
      asset: [] as any[],
      liability: [] as any[],
      equity: [] as any[],
    };

    let chartData = stockDetails.balance_sheet;

    if (
      !("Aset" in chartData) ||
      !("Liabilitas" in chartData) ||
      !("Ekuitas" in chartData)
    ) {
      return null;
    }

    let lastYear = 0;
    let currentYear = 0;

    (chartData.label as string[]).forEach((item, index) => {
      if (index !== 0) {
        let year = parseInt(item.split(" ")[1]);
        if (year) {
          if (lastYear === 0) {
            lastYear = year;
          }

          if (year === lastYear) {
            returnData.labels.push(item);
          } else if (currentYear !== year) {
            returnData.labels.push(item);
            currentYear = year;
          }
        }
      }
    });

    for (let i = 0; i < returnData.labels.length; i++) {
      let asset = parseFloat(
        chartData["Aset"][returnData.labels[i]]
          .replaceAll(" ", "")
          .replaceAll(",", "")
      );
      let liability = parseFloat(
        chartData["Liabilitas"][returnData.labels[i]].replace(",", "")
      );
      let equity = parseFloat(
        chartData["Ekuitas"][returnData.labels[i]].replace(",", "")
      );

      returnData.asset.push(asset);
      returnData.liability.push(liability);
      returnData.equity.push(equity);
    }

    return returnData;
  };

  const getPMChartData = () => {
    let returnData = {
      labels: [] as string[],
      gpm: [] as any[],
      opm: [] as any[],
      npm: [] as any[],
    };

    let chartData = stockDetails.graph;

    if (
      !("GPM" in chartData) ||
      !("NPM" in chartData) ||
      !("OPM" in chartData)
    ) {
      return null;
    }

    let lastYear = 0;
    let currentYear = 0;

    (chartData.label as string[]).forEach((item, index) => {
      if (index !== 0) {
        let year = parseInt(item.split(" ")[1]);
        if (year) {
          if (lastYear === 0) {
            lastYear = year;
          }

          if (year === lastYear) {
            returnData.labels.push(item);
          } else if (currentYear !== year) {
            returnData.labels.push(item);
            currentYear = year;
          }
        }
      }
    });

    for (let i = 0; i < returnData.labels.length; i++) {
      returnData.gpm.push(chartData["GPM"][returnData.labels[i]]);
      returnData.npm.push(chartData["NPM"][returnData.labels[i]]);
      returnData.opm.push(chartData["OPM"][returnData.labels[i]]);
    }

    return returnData;
  };

  const getROEChartData = () => {
    let returnData = {
      labels: [] as string[],
      roe: [] as any[],
    };

    let chartData = stockDetails.graph;

    if (!("ROE" in chartData)) {
      return null;
    }

    let lastYear = 0;
    let currentYear = 0;

    (chartData.label as string[]).forEach((item, index) => {
      if (index !== 0) {
        let year = parseInt(item.split(" ")[1]);
        if (year) {
          if (lastYear === 0) {
            lastYear = year;
          }

          if (year === lastYear) {
            returnData.labels.push(item);
          } else if (currentYear !== year) {
            returnData.labels.push(item);
            currentYear = year;
          }
        }
      }
    });

    for (let i = 0; i < returnData.labels.length; i++) {
      returnData.roe.push(chartData["ROE"][returnData.labels[i]]);
    }

    return returnData;
  };

  const getDupontAnalysisChartData = () => {
    let returnData = {
      labels: [] as string[],
      npm: [] as any[],
      assetTurnover: [] as any[],
      fl: [] as any[],
    };

    let chartData = stockDetails.graph;

    if (
      !("NPM" in chartData) ||
      !("FL" in chartData) ||
      !("Aset Turnover" in chartData)
    ) {
      return null;
    }

    let lastYear = 0;
    let currentYear = 0;

    (chartData.label as string[]).forEach((item, index) => {
      if (index !== 0) {
        let year = parseInt(item.split(" ")[1]);
        if (year) {
          if (lastYear === 0) {
            lastYear = year;
          }

          if (year === lastYear) {
            returnData.labels.push(item);
          } else if (currentYear !== year) {
            returnData.labels.push(item);
            currentYear = year;
          }
        }
      }
    });

    for (let i = 0; i < returnData.labels.length; i++) {
      returnData.npm.push(chartData["NPM"][returnData.labels[i]]);
      returnData.fl.push(chartData["FL"][returnData.labels[i]]);
      returnData.assetTurnover.push(
        chartData["Aset Turnover"][returnData.labels[i]]
      );
    }

    return returnData;
  };

  const getNetIncomeAndClosingPriceChartProps: () => ChartProps = () => {
    let chartData = getIncomeChartData();

    let options = {
      ...defaultOptions,
      scales: {
        y: {
          type: "linear" as const,
          display: true,
          title: {
            display: true,
            text: "Closing Price",
            color: "white",
            font: {
              size: 14,
            },
          },
          position: "left" as const,
          grid: {
            drawOnChartArea: false,
          },
        },
        y1: {
          type: "linear" as const,
          display: true,
          title: {
            display: true,
            text: "Net Income",
            color: "white",
            font: {
              size: 14,
            },
          },
          position: "right" as const,
          grid: {
            drawOnChartArea: false,
          },
        }
      },
      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: ["Net Income & Closing Price", "(dalam miliar rupiah)"],
        },
      },
    };

    let data = {
      labels: chartData.labels.reverse(),
      datasets: [
        {
          label: "Closing Price",
          data: chartData.closing.reverse(),
          borderColor: "rgb(255, 0, 0)",
          backgroundColor: "rgba(255, 0, 0, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Net Income (TTM)",
          data: chartData.net.reverse(),
          borderColor: "rgb(225, 221, 40)",
          backgroundColor: "rgba(225, 221, 40)",
          yAxisID: "y1",
        },
      ],
    };

    return {
      data: data,
      options: options,
      type: MidasChartType.LINE,
    };
  };

  const getTotalIncomeAndProfitChartProps: () => ChartProps = () => {
    let options = {
      ...defaultOptions,

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: ["Total Pendapatan & Laba Bersih", "(dalam miliar rupiah)"],
        },
      },
    };

    let chartData = getTotalIncomeAndProfitChartData();

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "Total Pendapatan",
              data: chartData.total.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
            {
              label: "Laba Bersih",
              data: chartData.profit.reverse(),
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.BAR,
    };
  };

  const getBalanceSheetsChartProps: () => ChartProps = () => {
    let options = {
      ...defaultOptions,

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: ["Balance Sheet", "(dalam miliar rupiah)"],
        },
      },
    };

    let chartData = getBalanceSheetChartData();

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "Aset",
              data: chartData.asset.reverse(),
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
            },
            {
              label: "Liabilitas",
              data: chartData.liability.reverse(),
              borderColor: "#DB3F2A",
              backgroundColor: "#DB3F2A",
              yAxisID: "y",
            },
            {
              label: "Ekuitas",
              data: chartData.equity.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.BAR,
    };
  };

  const getPMChartProps: () => ChartProps = () => {
    let options = {
      ...defaultOptions,

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: "GPM, OPM, NPM",
        },
      },
    };

    let chartData = getPMChartData();

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "GPM",
              data: chartData.gpm.reverse(),
              borderColor: "#DB3F2A",
              backgroundColor: "#DB3F2A",
              yAxisID: "y",
            },
            {
              label: "OPM",
              data: chartData.opm.reverse(),
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
            },
            {
              label: "NPM",
              data: chartData.npm.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.LINE,
    };
  };

  const getROEChartProps: () => ChartProps = () => {
    let options = {
      ...defaultOptions,

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: "ROE",
        },
      },
    };

    let chartData = getROEChartData();

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "ROE",
              data: chartData.roe.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.LINE,
    };
  };

  const getDupontAnalysisChartProps: () => ChartProps = () => {
    let chartData = getDupontAnalysisChartData();

    let options = {
      ...defaultOptions,

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: "Dupont Analysis",
        },
      },
      scales: {
        y: {
          type: "linear" as const,
          display: true,
          position: "left" as const,
          grid: {
            drawOnChartArea: false,
          },
        }
      },
    };

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "NPM",
              data: chartData.npm.reverse(),
              borderColor: "#DB3F2A",
              backgroundColor: "#DB3F2A",
              yAxisID: "y",
            },
            {
              label: "Aset Turnover",
              data: chartData.assetTurnover.reverse(),
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
            },
            {
              label: "FL",
              data: chartData.fl.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.LINE,
    };
  };

  const getFinancialStatementsTableProps: () => TableProps = () => {
    let chartData = stockDetails.financial_statements;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "",
      label: chartData[0],
      data: chartData.slice(1),
      freezeColumn:false,
    };
  };

  const getIndicatorTableProps: () => TableProps = () => {
    let chartData = stockDetails.indicator;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "",
      label: chartData[0],
      data: chartData.slice(1),
      freezeColumn:false,
    };
  };

  const getDebtTableProps: () => TableProps = () => {
    let chartData = stockDetails.debt;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "",
      label: chartData[0],
      data: chartData.slice(1),
      freezeColumn:false,
    };
  };

  const getEfficiencyTableProps: () => TableProps = () => {
    let chartData = stockDetails.efficiency;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "",
      label: chartData[0],
      data: chartData.slice(1),
      freezeColumn:false,
    };
  };

  const getRatioTableProps: () => TableProps = () => {
    let chartData = stockDetails.valuation;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "",
      label: chartData[0],
      data: chartData.slice(1),
      freezeColumn:false,
    };
  };

  const isBySectorShowAsTable = () => {
    if (stockDetails.by_sectors.length === 0) return false;

    return stockDetails.by_sectors[0].isTable;
  }

  const getEmitenBySectorTableData: () => TableProps = () => {
    if (stockDetails.by_sectors.length === 0)
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };

    let chartData = stockDetails.by_sectors[0].emitenData;

    if (!("tableData" in chartData)) return {
      title: "",
      label: [],
      data: [],
      freezeColumn:false,
    };

    return {
      title: "",
      label: chartData["tableData"][0],
      data: chartData["tableData"].slice(1),
      freezeColumn:false,
    };
  };

  const getBySectorChartData = () => {
    let returnData = {
      labels: [] as string[],
      barChartTitle: [] as string[],
      barChartData: [] as any[],
      lineChartTitle: [] as string[],
      lineChartData: [] as any[],
    };

    if (stockDetails.by_sectors.length === 0) return null;

    let chartData = stockDetails.by_sectors[0].emitenData;

    if (
      !("barChartData" in chartData) ||
      !("lineChartData" in chartData)
    ) {
      return null;
    }

    (chartData.labels as string[]).forEach((item, index) => {
      if (index !== 0) {
        returnData.labels.push(item);
      }
    });

    for (let i = 0; i < chartData.labels.length; i++) {
      if (chartData["barChartData"].length <= i || chartData["lineChartData"].length <= i) continue;

      if (i === 0) {
        returnData.barChartTitle = chartData["barChartData"][i];
        returnData.lineChartTitle = chartData["lineChartData"][i];
        continue;
      }

      let barChartData = chartData["barChartData"][i].map((item: string) => parseFloat(item));
      let lineChartData = chartData["lineChartData"][i].map((item: string) => parseFloat(item));

      returnData.barChartData.push(barChartData);
      returnData.lineChartData.push(lineChartData);
    }

    return returnData;
  };

  const getBySectorBarChartProps: () => ChartProps = () => {
    let chartData = getBySectorChartData();

    let options = {
      ...defaultOptions,
      scales: {
        y: {
          type: "linear" as const,
          display: true,
          title: {
            display: true,
            text: "dalam Juta Ton",
            color: "white",
            font: {
              size: 14,
            },
          },
          position: "left" as const,
          grid: {
            drawOnChartArea: false,
          },
        },
      },

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: [""],
        },
      },
    };

    let data = null;

    if (chartData !== null) {
      let barData: { [key: string]: number[] } = {};

      chartData.barChartData[0].forEach((_: any[], index: number) => {
        barData[index] = [];
      });

      chartData.barChartData.forEach((item: any[]) => item.forEach((barItem: string, index: number) => barData[index].push(parseFloat(barItem))));

      data = {
        labels: chartData.labels.reverse(),
        datasets: Object.keys(barData).map((key: string, index: number) => (
          index === 0 ?
            {
              label: (chartData && chartData.barChartTitle[parseInt(key)] !== "") ? chartData.barChartTitle[parseInt(key)] : `Bar${parseInt(key) + 1}`,
              data: barData[key].reverse(),
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
            } : index === Object.keys(barData).length - 1 ?
              {
                label: (chartData && chartData.barChartTitle[parseInt(key)] !== "") ? chartData.barChartTitle[parseInt(key)] : `Bar${parseInt(key) + 1}`,
                data: barData[key].reverse(),
                borderColor: (parseInt(key) === 1) ? "#DB3F2A" : "#FFDD28",
                backgroundColor: (parseInt(key) === 1) ? "#DB3F2A" : "#FFDD28",
                yAxisID: "y",
              } :
              {
                label: (chartData && chartData.barChartTitle[parseInt(key)] !== "") ? chartData.barChartTitle[parseInt(key)] : `Bar${parseInt(key) + 1}`,
                data: barData[key].reverse(),
                borderColor: (parseInt(key) === 1) ? "#DB3F2A" : "#FFDD28",
                backgroundColor: (parseInt(key) === 1) ? "#DB3F2A" : "#FFDD28",
              }

        ))
      }
    }

    return {
      data: data,
      options: options,
      type: MidasChartType.BAR,
    };
  };

  const getBySectorLineChartProps: () => ChartProps = () => {
    let options = {
      ...defaultOptions,

      scales: {
        y: {
          type: "linear" as const,
          display: true,
          position: "left" as const,
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "Sales (in $ millions)",
            color: "white",
            font: {
              size: 14,
            }
          }
        },
        y1: {
          type: "linear" as const,
          display: true,
          position: "right" as const,
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "ASP ($ per ton)",
            color: "white",
            font: {
              size: 14,
            }
          }
        },
      },

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: [""],
        },
      },
    };

    let chartData = getBySectorChartData();

    let data = null;

    if (chartData !== null) {
      let lineData: { [key: string]: number[] } = {};

      chartData.lineChartData[0].forEach((_: any[], index: number) => {
        lineData[index] = [];
      });

      chartData.lineChartData.forEach((item: any[]) => item.forEach((lineItem: string, index: number) => lineData[index].push(parseFloat(lineItem))));

      data = {
        labels: chartData.labels.reverse(),
        datasets: Object.keys(lineData).map((key: string) => (
          parseInt(key) === 0 ?
            {
              label: (chartData && chartData.lineChartTitle[parseInt(key)] !== "") ? chartData.lineChartTitle[parseInt(key)] : `Line${parseInt(key) + 1}`,
              data: lineData[key].reverse(),
              type: "bar",
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
              order: 2
            } :
            {
              label: (chartData && chartData.lineChartTitle[parseInt(key)] !== "") ? chartData.lineChartTitle[parseInt(key)] : `Line${parseInt(key) + 1}`,
              data: lineData[key].reverse(),
              type: "line",
              borderColor: (parseInt(key) === 1) ? "#FFDD28" : "#DB3F2A",
              backgroundColor: (parseInt(key) === 1) ? "#FFDD28" : "#DB3F2A",
              yAxisID: "y1",
              order: 1
            }

        ))
      }
    }

    return {
      data: data,
      options: options,
      type: MidasChartType.MULTIPLE,
    };
  };

  const getBankingLoanDepositChartProp: () => ChartProps = () => {
    let options = {
      ...defaultOptions,
      scales: {
        y: {
          type: "linear" as const,
          display: true,
          title: {
            display: true,
            text: "Rp Miliar",
            color: "white",
            font: {
              size: 14,
            },
          },
          position: "left" as const,
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: ["Loans & Deposits"],
        },
      },
    };

    let chartData = getBankingLoanDepositChartData();

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "Total Deposits",
              data: chartData.deposits.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
            {
              label: "Total Loans",
              data: chartData.loans.reverse(),
              borderColor: "#34866E",
              backgroundColor: "#34866E",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.BAR,
    };
  };

  const getBankingDataLoanQuality: () => TableProps = () => {
    let chartData = stockDetails.banking_loan_quality;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "Loan Quality",
      label: chartData[0],
      data: chartData.slice().splice(1,chartData.length),
      freezeColumn:false,
    };
  };

  const getBankingDataIncome: () => TableProps = () => {
    let chartData = stockDetails.banking_income;

    if (chartData.length <= 1) {
      return {
        title: "",
        label: [],
        data: [],
        freezeColumn:false,
      };
    }

    return {
      title: "Income Statement",
      label: chartData[0],
      data: chartData.slice().splice(1,chartData.length),
      freezeColumn:false,
    };
  };

  const getBankingDevidenChartProp: () => ChartProps = () => {
    let options = {
      ...defaultOptions,

      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: ["Deviden Per Share"],
        },
      },
    };

    let chartData = getBankingDevidenChartData();

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels.reverse(),
          datasets: [
            {
              label: "Deviden Per Share",
              data: chartData.deviden.reverse(),
              borderColor: "#FFDD28",
              backgroundColor: "#FFDD28",
              yAxisID: "y",
            },
          ],
        };

    return {
      data: data,
      options: options,
      type: MidasChartType.BAR,
    };
  };

  const getBankingIncomeWaterfallChart: () => ChartProps = () => {
    let options = {
      ...defaultOptions,
      scales: {
        y: {
          type: "linear" as const,
          display: true,
          title: {
            display: true,
            text: "Rp Miliar",
            color: "white",
            font: {
              size: 14,
            },
          },
          position: "left" as const,
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      plugins: {
        ...defaultOptions.plugins,

        title: {
          ...defaultOptions.plugins.title,
          text: [""],
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: () => null ,
            footer: (tooltipItems:[any])=>{
              let sum = 0;
              let label = "a" as string;
              let color = "a" as string; 

              tooltipItems.forEach((tooltipItem, chart)=>{
                sum = tooltipItem.dataset.data[tooltipItem.parsed.x][1]-tooltipItem.dataset.data[tooltipItem.parsed.x][0];
                label = tooltipItem.label;
                color = tooltipItem.dataset.backgroundColor[tooltipItem.parsed.x]
              });
              return sum ;
            },
          }
        },
        datalabels: {
          anchor: 'center', // Position of the labels (start, end, center, etc.)
          align: 'center', // Alignment of the labels (start, end, center, etc.)
          color: 'white', // Color of the labels
          textStrokeColor: 'black',
          textStrokeWidth: 2,
          font: {
              weight: 'bold',
          },
          formatter: (value:any, context:any) => {
            return context.chart.data.datasets[0].data[context.dataIndex][1] - context.chart.data.datasets[0].data[context.dataIndex][0];
          }
        }
      },
    };

    let chartData = getBankingIncomeWaterfallChartData();
    
    let dataWaterfallColor = chartData.waterfallData[0].map((item:any, index:any) =>(
      // index
      item[0]>item[1] ? "red" : index === chartData.waterfallData[0].length-1 ? "#FFDD28" : "#34866E"
    ));

    let data =
      chartData === null
        ? null
        : {
          labels: chartData.labels,
          datasets:
          [
            {
              label: "",
              data: chartData.waterfallData[0],
              borderColor: dataWaterfallColor,
              backgroundColor:  dataWaterfallColor,
              yAxisID: "y",
            },
          ],
        }
    return {
      data: data,
      options: options,
      type: MidasChartType.BAR,
    };
  };  

  const buildViewSectoral = () => {
    switch (stockDetails?.by_sectors[0]?.sectorTitle){
      case "Bank": 
        return (<div className="flex flex-col gap-5 mt-3">

          <div className="text-xs grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4 content-center">
            <MidasChart {...getBankingIncomeWaterfallChart()} />
            <TableComponent {...getBankingDataIncome()} />
          </div>
          
          <MidasChart {...getBankingDevidenChartProp()} />

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4 content-center">
            <MidasChart {...getBankingLoanDepositChartProp()} />
            <TableComponent {...getBankingDataLoanQuality()} />
          </div>
        </div>
        )
      default:
        return <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
            <MidasChart {...getTotalIncomeAndProfitChartProps()} />
            <MidasChart {...getBalanceSheetsChartProps()} />
            <MidasChart {...getPMChartProps()} />
            <MidasChart {...getROEChartProps()} />
          </div>

          <div className="mt-4">
            <MidasChart {...getDupontAnalysisChartProps()} />
          </div>

          {stockDetails?.by_sectors?.length !== 0 && <div>
            <div className="mt-4 text-center font-bold text-base">
              Additional Information
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {!isBySectorShowAsTable() && <MidasChart {...getBySectorBarChartProps()} />}
              {!isBySectorShowAsTable() && <MidasChart {...getBySectorLineChartProps()} />}

              {isBySectorShowAsTable() && <div className="col-span-2"><TableComponent {...getEmitenBySectorTableData()} /></div>}
            </div>

          </div>}

          {buildPeriodForm()}

          <div className="my-4 w-full">
            <div className="text-center font-bold text-base">
              Laporan Keuangan
            </div>
            <TableComponent {...getFinancialStatementsTableProps()} />
          </div>

          <JarvisCalculationComponent
            indicatorData={getIndicatorTableProps()}
            debtData={getDebtTableProps()}
            eficiencyData={getEfficiencyTableProps()}
            ratioData={getRatioTableProps()}
          />
        </>
    }
  }
  return (
    <div className="mt-6 text-white">
      <Helmet encodeSpecialCharacters={true}>
        <title>JARVIS - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - JARVIS" />
      </Helmet>
      {listOfStocks.length > 0 && (
        <>
          <div className="w-full flex flex-col md:flex-row gap-4">
            <div className="my-auto w-full md:w-1/4">Cari kode saham</div>
            <Select
              className="basic-single w-full p-3 text-black"
              isSearchable={true}
              onChange={(newValue) =>
                setSelectedParams({
                  ...selectedParams,
                  stock: newValue?.value ?? "",
                })
              }
              options={listOfStocks.map((item) => {
                return {
                  value: item.code,
                  label: item.code + " - " + item.name,
                };
              })}
            />
          </div>
          {buildPeriodForm()}
        </>
      )}
      {isLoading || selectedParams.stock === "" || stockDetails === null ? (
        <div className="mt-6">
          <MidasShimmering />
        </div>
      ) : (
        <div className="jarvis-container-small lg:jarvis-container">
          <div className="mt-4">
            <MidasChart {...getNetIncomeAndClosingPriceChartProps()} />
          </div>
          {buildViewSectoral()}
        </div>
      )}
    </div>
  );
};
