import { client } from "@Libraries/networks/ApiServices";
import { AffiliateLandingPageModel } from "../data/affiliate-product.model";
import { ApiConst } from "@Libraries/networks/ApiConst";

export const GetAffiliateLandingPagesUsecase: () => Promise<
  AffiliateLandingPageModel[]
> = async () => {
  try {
    let response = await client.get(ApiConst.API_AFFILIATES + "/landingPages");
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.status.toString());
  }
};
