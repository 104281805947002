import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const RemoveProductUsecase: (
  orderId: string,
  productId: string
) => Promise<boolean> = async (orderId, productId) => {
  try {
    await client.delete(
      ApiConst.API_REMOVE_PRODUCT + "/" + orderId + "/" + productId
    );
    return true;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
