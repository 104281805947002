import { EyeIcon, EyeSlashIcon } from "@Assets/common.assets";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { ChangePasswordUsecase } from "@Features/profile/domain/ChangePassword.usecase";
import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import { DividerComponent } from "@Libraries/components/divider/Divider.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { UserSession } from "@Libraries/users/UserSession";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type ChangePasswordFormInputs = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

export const ChangePasswordSectionComponent: FC = () => {
  const toasterContext = UseToasterContext();
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState<boolean>(false)
  const [errorMessage,setErrorMessage] = useState<string>()
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewComPassword, setShowNewComPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ChangePasswordFormInputs>();

  const onSubmit = (data: ChangePasswordFormInputs) => {
    let formData = new FormData();

    formData.append("old_password", data.currentPassword);
    formData.append("new_password", data.newPassword);
    formData.append("new_password_confirmation", data.newPasswordConfirm);

    setIsLoading(true)

    ChangePasswordUsecase(formData)
      .then((response) => {
        setIsLoading(false)
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Password berhasil di ubah!",
            "",
            ToasterType.SUCCESS
          ),
        ]);

        setTimeout(function () {
          UserSession.logout().then(() => navigate("/login"));
        }, 1000);
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorMessage(error.message)
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Password gagal di ubah!",
            error.message,
            ToasterType.DANGER
          ),
        ])
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-xl font-bold">Data Diri</div>

      <div className="mt-8 flex flex-col w-full">
        <label className="font-normal">Password Sekarang</label>
        <div className="flex w-full bg-282828 p-2 border-none rounded-md mt-2 text-sm items-center">
          <input
            type={showCurrentPassword ? "text" : "password"}
            className="px-3 py-2 w-full bg-282828 outline-none"
            placeholder="Masukkan password saat ini"
            {...register("currentPassword", {
              required: "Current password is required.",
            })}
          />
          <div
            className="w-6 h-6 color-brand-yellow"
            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
          >
            {showCurrentPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
        {errors.currentPassword && (
          <p className="text-red-500 text-xs">
            {errors.currentPassword.message}
          </p>
        )}

      </div>

      <div className="mt-8 flex flex-col w-full">
        <label className="font-normal">Password Baru</label>
        <div className="flex w-full bg-282828 p-2 border-none rounded-md mt-2 text-sm items-center">
          <input
            type={showNewPassword ? "text" : "password"}
            className="px-3 py-2 w-full bg-282828 outline-none"
            placeholder="Masukkan password baru"
            {...register("newPassword", {
              required: "New password is required.",
            })}
          />
          <div
            className="w-6 h-6 color-brand-yellow"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
        {errors.newPassword && (
          <p className="text-red-500 text-xs">{errors.newPassword.message}</p>
        )}
      </div>

      <div className="mt-8 flex flex-col w-full">
        <label className="font-normal">Konfirmasi Password Baru</label>
        <div className="flex w-full bg-282828 p-2 border-none rounded-md mt-2 text-sm items-center">
          <input
             type={showNewComPassword ? "text" : "password"}
            className="px-3 py-2 w-full bg-282828 outline-none"
            placeholder="Masukkan konfirmasi password baru"
            {...register("newPasswordConfirm", {
              required: "New password confirmation is required.",
              validate: (val: string) => {
                if (watch("newPassword") !== val) {
                  return "Konfirmasi dan Password baru tidak sama!";
                }
              },
            })}
          />
          <div
            className="w-6 h-6 color-brand-yellow"
            onClick={() => setShowNewComPassword(!showNewComPassword)}
          >
            {showNewComPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
        {errors.newPasswordConfirm && (
          <p className="text-red-500 text-xs">
            {errors.newPasswordConfirm.message}
          </p>
        )}
      </div>

      <DividerComponent />

      <p className="text-red-500 text-xs">
        {!isLoading && errorMessage}
      </p>

      <div className="flex flex-col lg:flex-row justify-end mt-8">
        <div className="my-auto mx-auto lg:mx-0 mt-4 lg:mt-0">
          <button disabled>
            <MidasRoundedYellowButton
              disabled={isLoading}
              label={isLoading ? "Sedang Diproses..." : "Ubah Password"}
              onClick={() => {}}
              isExternalLink={false}
            />
          </button>
        </div>
      </div>

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </form>
  );
};
