import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AnalysisModel } from "../data/analysis.model";

export const GetAnalysisListUsecase: (page: number, searchKeyword: string) => Promise<
  AnalysisModel[]
> = async (page, searchKeyword) => {
  try {
    let searchQuery = searchKeyword != "" ? `&search=${searchKeyword.replaceAll(" ", "+")}` : "";
    let response = await client.get(
      ApiConst.API_ANALYSIS + `?status=published&limit=8&page=${page}${searchQuery}`
    );
    let data: AnalysisModel[] = response.data.data.data.map((item: any) => {
      return {
        id: item.id.toString(),
        buy_price: item.buy_price,
        code: item.code,
        image_url: item.image_url,
        margin_of_safety: item.margin_of_safety,
        current_price: item.current_price,
        valuation: item.valuation,
        changes: item.changes,
        slug: item.page.slug,
        created_at: item.page.created_at,
        title: item.page.title
      };
    });
    data.push(response.data.data.meta)

    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};