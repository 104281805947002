import { FC, ReactNode } from "react";

export type CheckoutLeftFormProps = {
  bodyContent: ReactNode;
  nextButton: ReactNode;
};

export const CheckoutLeftForm: FC<CheckoutLeftFormProps> = ({
  bodyContent,
  nextButton,
}) => {
  return (
    <>
      {bodyContent}

      <div className="flex justify-end mt-6 order-2 md:order-1 mb-6">
        {nextButton}
      </div>
    </>
  );
};
