import { FC } from "react";

import { TextWithRightImageComponent } from "@Libraries/components/text-image/TextWithRightImage.component";

import { default as KeyPdf } from "@Assets/stock-insight/key-tersedia-dalam-bentuk-pdf.png";
import { default as KeyFinancial } from "@Assets/stock-insight/key-financial.png";
import { default as KeyEasyToUnderstand } from "@Assets/stock-insight/key-mudah-dipahami.png";
import { default as KeyCompact } from "@Assets/stock-insight/key-singkat-jelas-padat.png";
import { default as KeyStatistics } from "@Assets/stock-insight/key-statistics.png";

import { default as FeaturesImage } from "@Assets/stock-insight/stock-key-insights-features.png";

export type FeatureItemProps = {
  image: string;
  title: string;
  description: string;
};

export const FeatureItem: FC<FeatureItemProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <div className="w-full flex py-4">
      <img src={image} className="object-cover w-16 h-16" alt="" />
      <div className="ml-10">
        <div className="text-2xl text-white font-semibold">{title}</div>
        <div className="text-base text-white font-normal mt-2">
          {description}
        </div>
      </div>
    </div>
  );
};

export const FeaturesSection = () => {
  const featuresList = [
    {
      image: KeyPdf,
      title: "Tersedia dalam bentuk PDF",
      description: "PDF berisi ulasan detail sebuah emiten",
    },
    {
      image: KeyCompact,
      title: "Singkat, Padat dan Jelas",
      description:
        "Penjelasan mengenai company profile sebuah emiten agar dapat mengenali bisnis dari emiten tersebut secara menyeluruh dan mudah dimengerti",
    },
    {
      image: KeyEasyToUnderstand,
      title: "Tuntas, Padat, Mudah Dipahami",
      description:
        "Cocok untuk Anda yang tidak ada waktu untuk analisa emiten secara detail",
    },
    {
      image: KeyStatistics,
      title: "Key Statistics",
      description:
        "Poin penting sebagai tolak ukur sebuah saham (perusahaan) bagus atau tidak yang sudah kita rumuskan berdasarkan pengalaman berinvestasi dan menghasilkan return fantastis sejak 2014",
    },
    {
      image: KeyFinancial,
      title: "Key Financial",
      description:
        "Berisi item super penting dari Lapkeu yang sudah kita paparkan secara simple dan sangat mudah dipahami bahkan bagi yang awam sekalipun, sehingga anda akan dengan mudah memahami dan menyimpulkan keputusan investasi terbaik Anda",
    },
  ];

  return (
    <div className="mt-10 md:mt-20">
      <div className="mx-7 md:mx-28 font-extrabold text-white responsive-header-title md:m-auto text-center md:text-left">
        Fitur MIDAS,{" "}
        <div className="color-brand-yellow inline-block md:block">
          Stock Key Insight
        </div>
      </div>
      <div className="mt-10">
        <TextWithRightImageComponent
          textChildren={
            <div>
              {featuresList.map((item, index) => {
                return <FeatureItem key={`Key-Features-${index}`} {...item} />;
              })}
            </div>
          }
          image={
            <img
              src={FeaturesImage}
              alt="MidasCuan - Fitur MIDAS, Stock Key Insight"
            />
          }
          showButton={false}
        />
      </div>
    </div>
  );
};
