import { FC } from "react";
import { ReactElement } from "react";

export type FooterSectionItemProps = {
  icon?: ReactElement;
  label: string;
  url: string;
  target: string;
};

export type FooterSectionProps = {
  title: string;
  items: FooterSectionItemProps[];
};

export const FooterSectionComponent: FC<FooterSectionProps> = ({
  title,
  items,
}) => {
  return (
    <div className="w-full">
      <p className="color-brand-yellow text-base font-bold">{title}</p>
      {items.map((item) => {
        return (
          <a
            href={item.url}
            key={`${item.label}-${item.url}`}
            className="flex no-underline mt-6"
            target={item.target}
          >
            {item.icon != null && (
              <div className="color-brand-yellow my-auto">{item.icon}</div>
            )}
            <div
              className={`text-white font-normal text-base ${
                item.icon != null && "ml-2"
              }`}
            >
              {item.label}
            </div>
          </a>
        );
      })}
    </div>
  );
};
