import { AutoPlayCarouselComponent } from "@Libraries/components/carousels/AutoPlayCarousel.component";
import {
  CarouselListModel,
  CarouselType,
} from "@Libraries/components/carousels/models/carousel-list.model";
import { FC, useState } from "react";

export type CriteriaModel = {
  text: string;
  imageUrl: string;
};

export type CriteriaProps = {
  data: CriteriaModel[];
};

export const CriteriaCarouselSection: FC<CriteriaProps> = ({ data }) => {
  const onNext = () => {
    let newCarouselState = { ...carouselState };
    if (carouselState.focusedIndex + 1 >= carouselState.carouselList.length) {
      carouselState.focusedIndex = 0;
      newCarouselState.focusedIndex = 0;
    } else {
      newCarouselState.focusedIndex = carouselState.focusedIndex + 1;
      carouselState.focusedIndex = carouselState.focusedIndex + 1;
    }

    setCarouselState(newCarouselState);
  };

  const [carouselState, setCarouselState] = useState<CarouselListModel>({
    carouselId: "investmentPhilosophyLinearCarousel",
    type: CarouselType.LINEAR_TEXT,
    focusedIndex: 0,
    carouselList: data.map((item, index) => {
      return {
        key: `philosophy-criteria-${index}`,
        imageUrl: item.imageUrl,
        altText: "",
        text: item.text,
      };
    }),
  });

  return (
    <div className="mt-14 md:mt-28">
      <div className="mx-20 md:mx-40 text-lg font-medium text-white text-center">
        Kita percaya, dibalik setiap lembar saham ada perusahaan nyata yang
        bekerja. Untuk itu, kita berfokus untuk mencari perusahaan-perusahaan
        yang mampu:
      </div>
      <div className="mt-10 md:mt-20 mx-4 md:mx-0">
        <AutoPlayCarouselComponent
          {...carouselState}
          onNext={onNext}
          onPrev={() => {}}
          duration={2000}
        />
      </div>
    </div>
  );
};
