import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { CourseModel } from "../data/course.model";

export const GetCourseListUsecase: () => Promise<CourseModel[]> = async () => {
  try {
    let response = await client.get(
      ApiConst.API_COURSE + "?status=published&limit=1000"
    );

    let newList = [...response.data.data.list];

    let durationInSeconds = 0;
    let videoCount = 0;

    newList.forEach((item: CourseModel) => {
      item.sections.forEach((section) => {
        videoCount += section.items.length;
        section.items.forEach((course) => {
          durationInSeconds += parseInt(course.duration_in_seconds);
        });
      });

      let durationHours = Math.floor(durationInSeconds / (60 * 60));
      durationInSeconds -= durationHours * 60 * 60;
      let durationMinutes = Math.floor(durationInSeconds / 60);
      durationInSeconds -= durationMinutes * 60;

      let durationString = `${
        durationHours > 10 ? durationHours : `0${durationHours}`
      }:${durationMinutes > 10 ? durationMinutes : `0${durationMinutes}`}:${
        durationInSeconds > 10 ? durationInSeconds : `0${durationInSeconds}`
      }`;

      item.part = item.sections.length.toString();
      item.duration = durationString;
      item.videoCount = videoCount.toString();
    });

    return newList;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
