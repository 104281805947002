export const ApiConst = (function () {
  // let API_BASE_URL = "http://127.0.0.1:8000/";
  let API_BASE_URL = "https://api.midascuan.id/";

  let API_PATH = "";

  let API_PATH_AUTH = "auth/";
  let API_PATH_LOGIN = API_PATH_AUTH + "login";
  let API_PATH_LOGOUT = API_PATH_AUTH + "logout";
  let API_PATH_FORGOT_PASSWORD = API_PATH_AUTH + "forgot-password";
  let API_PATH_RESET_PASSWORD = API_PATH_AUTH + "reset-password";

  let API_PATH_PROFILE = "profile";
  let API_PATH_CHANGE_PASSWORD = "change-password";
  let API_PATH_CHANGE_PROFILE_PICT = "change-profile-pict";
  let API_PATH_CHECK_EXPIRY = "get-expired-notification";

  let API_PATH_AFFILIATES = "affiliates";

  let API_PATH_PAGES = "pages";

  let API_PATH_SETTINGS = "site-options";
  let API_PATH_LOAD_BATCH_SETTINGS = `${API_PATH_SETTINGS}/batch-load`;

  let API_PATH_RECORDS = "records";

  let API_PATH_COURSES = "courses";
  let API_PATH_COURSES_PROGRESS = API_PATH_COURSES + "/saveProgress";

  let API_PATH_PRODUCTS = "products";
  let API_PATH_ORDERS = "orders";
  let API_PATH_APPLY_VOUCHER = "apply-voucher";
  let API_PATH_REMOVE_VOUCHER = "remove-voucher";
  let API_PATH_REMOVE_PRODUCT = "remove-product";
  let API_PATH_CHECKOUT = "checkout";

  let API_PATH_JARVIS = "jarvis";
  let API_PATH_ANALYSIS = "analysis";
  let API_PATH_PORTFOLIO = "portfolios";
  let API_PATH_WATCHLIST = "watchlist";

  let API_PATH_STOCK_KEYS_LOAD = "stock-key/load";

  let API_PATH_TREASURE_MAPS = "treasures";

  return {
    API_URL: API_BASE_URL + API_PATH,
    API_AFFILIATES: API_PATH_AFFILIATES,
    API_LOGIN: API_PATH_LOGIN,
    API_FORGOT_PASSWORD: API_PATH_FORGOT_PASSWORD,
    API_RESET_PASSWORD: API_PATH_RESET_PASSWORD,
    API_LOGOUT: API_PATH_LOGOUT,
    API_PROFILE: API_PATH_PROFILE,
    API_CHANGE_PASSWORD: API_PATH_CHANGE_PASSWORD,
    API_CHANGE_PICT: API_PATH_CHANGE_PROFILE_PICT,
    API_CHECK_EXPIRY: API_PATH_CHECK_EXPIRY,
    API_PAGE: API_PATH_PAGES,
    API_SETTING: API_PATH_SETTINGS,
    API_LOAD_SETTINGS: API_PATH_LOAD_BATCH_SETTINGS,
    API_RECORD: API_PATH_RECORDS,
    API_COURSE: API_PATH_COURSES,
    API_COURSE_PROGRESS: API_PATH_COURSES_PROGRESS,
    API_ORDER: API_PATH_ORDERS,
    API_CHECKOUT: API_PATH_CHECKOUT,
    API_APPLY_VOUCHER: API_PATH_APPLY_VOUCHER,
    API_REMOVE_VOUCHER: API_PATH_REMOVE_VOUCHER,
    API_REMOVE_PRODUCT: API_PATH_REMOVE_PRODUCT,
    API_PRODUCT: API_PATH_PRODUCTS,
    API_JARVIS: API_PATH_JARVIS,
    API_ANALYSIS: API_PATH_ANALYSIS,
    API_PORTFOLIO: API_PATH_PORTFOLIO,
    API_STOCK_KEY: API_PATH_STOCK_KEYS_LOAD,
    API_TREASURE_MAPS: API_PATH_TREASURE_MAPS,
    API_WATCHLIST: API_PATH_WATCHLIST,
  };
})();

export const ApiFomo = (function () {

  let API_FOMO_BASE_URL = "https://api.fomo.com/api/v1/applications/S_v0N_oapIo9y7Aivp045A/webhooks/custom_webhook/225a83c464a67b0a746f45a3d28abb6c";

  return {
    API_FOMO : API_FOMO_BASE_URL
  };
})();
