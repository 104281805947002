import { AutoPlayCarouselComponent } from "@Libraries/components/carousels/AutoPlayCarousel.component";
import {
  CarouselListModel,
  CarouselType,
} from "@Libraries/components/carousels/models/carousel-list.model";
import { FC, useState } from "react";

export type TeaserModel = {
  imageUrl: string;
  altText: string;
  productId?: string;
  teaserUrl?: string;
};

export type TeaserSectionProps = {
  data: TeaserModel[];
};

export const TeaserSection: FC<TeaserSectionProps> = ({ data }) => {
  const [carouselState, setCarouselState] = useState<CarouselListModel>({
    carouselId: "stockKeyInsightsTeaserCarousel",
    type: CarouselType.LINEAR_TEASER,
    focusedIndex: 0,
    carouselList: data.map((item, index) => {
      return {
        key: `stock-key-insight-teaser-${index}`,
        imageUrl: item.imageUrl,
        altText: item.altText,
        productId: item.productId,
        teaserUrl: item.teaserUrl,
      };
    }),
  });

  const onNext = () => {
    let newCarouselState = { ...carouselState };
    if (carouselState.focusedIndex + 1 >= carouselState.carouselList.length) {
      carouselState.focusedIndex = 0;
      newCarouselState.focusedIndex = 0;
    } else {
      newCarouselState.focusedIndex = carouselState.focusedIndex + 1;
      carouselState.focusedIndex = carouselState.focusedIndex + 1;
    }

    setCarouselState(newCarouselState);
  };

  return (
    <div className="mt-16 mx-7 md:mx-16">
      <div className="text-white font-bold text-5xl text-center">
        Teaser <span className="color-brand-yellow">Stock Key Insight</span>
      </div>
      <div className="text-white text-xl font-normal mt-4 text-center">
        Lihat dulu teasernya dibawah ini yuk!
      </div>
      <div className="mt-10 md:mt-20">
        <AutoPlayCarouselComponent
          {...carouselState}
          onNext={onNext}
          onPrev={() => {}}
          duration={5000}
        />
      </div>
    </div>
  );
};
