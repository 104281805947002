import { ClickListenerType } from "@Features/common/presentation/listener/Click.listener";
import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import { FC } from "react";

import { MidasVideoPlayer } from "@Libraries/components/video-player/VideoPlayer.component";

export type TopHeaderModel = {
  videoUrl: string;
};

export type TopHeaderSectionProps = ClickListenerType & {
  data: TopHeaderModel;
};

export const TopHeaderSections: FC<TopHeaderSectionProps> = ({
  data,
  onClick,
}) => {
  return (
    <div className="mt-7 mx-7 md:mx-28 md:mt-13 text-left">
      <div className="mx-0 md:mx-4 responsive-header-title  text-white font-black text-center">
        Temukan Saham <span className="color-brand-yellow">“Harta Karun”</span>{" "}
        dan Invest dengan <span className="color-brand-yellow">CONFIDENCE</span>
      </div>
      <div className="mt-6 color-white-70 text-center font-normal text-xl">
        MIDAS Stocks Membership memberikan FRAMEWORK dan ROADMAP step-by-step
        untuk menemukan saham harta karun secara sederhana!
      </div>
      <div className="mt-8 flex justify-center">
        <MidasRoundedYellowButton
          label="Gabung sekarang"
          onClick={onClick ? onClick : () => {}}
          isExternalLink={false}
        />
      </div>

      {data.videoUrl !== "" && (
        <div className="mt-6 w-1/2 mx-auto">
          <MidasVideoPlayer
            componentKey="MidasCuan - Introduction"
            videoUrl={data.videoUrl}
            options={{
              widthPercentage: 100,
              showVideoControls: true,
              isAutoPlay: true,
              canDownload: false,
              videoSource: "url",
            }}
          />
        </div>
      )}
    </div>
  );
};
