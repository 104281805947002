/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { GetTreasureMapsList } from "@Features/dashboard/domain/GetTreasureMapsList.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { UserSession } from "@Libraries/users/UserSession";
import { FC, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { registerAllModules } from "handsontable/registry";
import { HotTable } from "@handsontable/react";
import { ContextMenu } from "handsontable/plugins";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import {
  STOCK_TREASURE_MAPS_TOOLS,
  STOCK_PORTOFOLIO_TOOLS,
} from "@Libraries/utils/Const";
import { GetToolsListUsecase } from "@Features/dashboard/domain/GetToolsList.usecase";

registerAllModules();

export type ToolsBaseItemProps = {
  showNewLabel: boolean;
  showBetaLabel: boolean;
};

export type ToolsItemProps = ToolsBaseItemProps & {
  imageUrl: string;
  text: ReactNode;
  url?: string;
};

export enum ToolsSortType {
  ASC,
  DESC,
}

export const ToolsItemComponent: FC<ToolsItemProps> = ({
  imageUrl,
  text,
  url,
  showNewLabel,
  showBetaLabel,
}) => {
  const buildView = () => {
    return (
      <div className="relative rounded-lg bg-midas-neutral-500 p-4 h-full hover:text-black ">
        <img src={imageUrl} alt="" className="w-1/2 mx-auto aspect-auto" />
        <div className="text-base font-semibold text-center mt-2">{text}</div>
        {showNewLabel && (
          <div
            className="absolute top-0 right-0 bg-red-500 rounded text-white px-2 py-1"
            style={{ fontSize: 10 }}
          >
            Terbaru!
          </div>
        )}
        {showBetaLabel && (
          <div
            className="absolute top-0 right-0 bg-red-500 rounded text-white px-2 py-1"
            style={{ fontSize: 10 }}
          >
            Beta!
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {url && (
        <a href={url} target={"_blank"} rel="noreferrer">
          {buildView()}
        </a>
      )}
    </>
  );
};

export const TreasureMapsComponent: FC<ToolsBaseItemProps> = ({
  showNewLabel,
  showBetaLabel,
}) => {
  const toasterContext = UseToasterContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);

  const [headerApiData, setHeaderApiData] = useState<string[]>();
  const [columns, setColumns] = useState<any>();
  const [dataToShow, setDataToShow] = useState<string[][]>();

  useEffect(() => {
    async function fetchData() {
      try {
        let data = await GetTreasureMapsList();
        let tableData = data.data;

        let columnsData: any[] = [];
        for (let i = 0; i < data.headers.length; i++) {
          if (i > 2 && i < data.headers.length - 1) {
            columnsData.push({
              type: "numeric",
              numericFormat: {
                pattern: {
                  thousandSeparated: true,
                },
                culture: "in_ID",
              },
            });
          } else {
            columnsData.push({});
          }
        }

        setColumns(columnsData);
        setHeaderApiData(data.headers);
        setDataToShow(tableData);
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal mengambil data Treasure Maps",
            "",
            ToasterType.DANGER
          ),
        ]);
      }
      setShowLoading(false);
    }
    fetchData();
  }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        className={`relative w-full bg-282828 flex justify-between p-4 ${showBetaLabel ? "pt-6" : ""
          // showNewLabel ? "pt-6" : ""
          } ${isOpen ? "rounded-t" : "rounded"}`}
        onClick={toggleOpen}
      >
        <div className="text-white text-base font-bold">
          Stocks Treasure maps
        </div>
        <div className="my-auto">
          {isOpen ? (
            <FaChevronUp className="text-white" size={16} />
          ) : (
            <FaChevronDown className="text-white" size={16} />
          )}
        </div>
        {showNewLabel && (
          <div
            className="absolute top-0 right-0 bg-red-500 rounded text-white px-2 py-1"
            style={{ fontSize: 10 }}
          >
            Terbaru!
          </div>
        )}
        {showBetaLabel && (
          <div
            className="absolute top-0 right-0 bg-red-500 rounded text-white px-2 py-1"
            style={{ fontSize: 10 }}
          >
            Beta!
          </div>
        )}
      </button>

      {isOpen && (
        <div className="p-4 bg-white rounded-b jarvis-container-small lg:jarvis-container">
          {showLoading && <MidasShimmering />}
          {!showLoading && (
            <div className="w-full max-h-screen overflow-auto">
              <HotTable
                className="text-base text-black htMiddle"
                readOnlyCellClassName="text-black"
                allowHtml={true}
                allowInsertColumn={false}
                allowInsertRow={false}
                allowRemoveRow={false}
                allowRemoveColumn={false}
                height={500}
                colWidths={150}
                licenseKey="non-commercial-and-evaluation"
                data={dataToShow}
                colHeaders={headerApiData}
                rowHeaders={true}
                fixedColumnsStart={3}
                autoRowSize={true}
                manualColumnResize={true}
                readOnly={true}
                copyable={false}
                copyPaste={false}
                columns={columns}
                columnSorting={{
                  indicator: true,
                  headerAction: true,
                  sortEmptyCells: false,
                }}
                hiddenColumns={{
                  indicators: true,
                }}
                filters={true}
                dropdownMenu={{
                  items: {
                    alignment: {},
                    sp1: ContextMenu.SEPARATOR,
                    hide: {
                      name: "Sembunyikan Kolom",
                      callback: function (_key, selection, _event) {
                        const hidePlugin = this.getPlugin("hiddenColumns");
                        hidePlugin.hideColumn(selection[0].start.col);
                        this.render();
                      },
                    },
                    show: {
                      name: "Tampilkan Semua Kolom",
                      callback: function () {
                        const hidePlugin = this.getPlugin("hiddenColumns");
                        hidePlugin.showColumns(hidePlugin.getHiddenColumns());
                        this.render();
                      },
                    },
                    sp2: ContextMenu.SEPARATOR,
                    filter_by_condition: {},
                    filter_by_value: {},
                    filter_action_bar: {},
                  },
                }}
              />
            </div>
          )}
        </div>
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};

export const ToolsSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSettings, setPageSettings] = useState<PageSettingListModel>();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      let response = await GetToolsListUsecase();
      setPageSettings(response);
    } catch (e) {
      // TODO
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mt-4 ">
      <Helmet encodeSpecialCharacters={true}>
        <title>Tools - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Tools" />
      </Helmet>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {UserSession.isUserCanAccessMemberArea(
          "member-area::tools::stock-treasure-sheet"
        ) && pageSettings && (
            <ToolsItemComponent
              imageUrl="https://api.midascuan.id/storage/resources/google-sheets-icon.png"
              text={"Stocks Treasure maps"}
              showNewLabel={true}
              url={pageSettings[STOCK_TREASURE_MAPS_TOOLS]}
              showBetaLabel={false}
            />
          )}
        {/* {UserSession.isUserCanAccessMemberArea(
          "member-area::tools::stock-portfolio"
        ) && pageSettings && (
            <ToolsItemComponent
              imageUrl="https://api.midascuan.id/storage/resources/google-sheets-icon.png"
              text={"Stock Portfolio Tools"}
              showNewLabel={false}
              url={pageSettings[STOCK_PORTOFOLIO_TOOLS]}
              showBetaLabel={false}
            />
          )} */}
        {/* {UserSession.isUserCanAccessMemberArea(
          "member-area::tools::dividen-discount-model"
        ) && (
          <ToolsItemComponent
            imageUrl="https://api.midascuan.id/storage/resources/google-sheets-icon.png"
            text={"Dividend Discount Model Template"}
            showNewLabel={false}
            url={
              "https://docs.google.com/spreadsheets/d/1uCgIIFXh0AHn22BzB6VJ5ciPy0uUECyBbBOIT0v4a5A"
            }
            showBetaLabel={false}
          />
        )}
        {UserSession.isUserCanAccessMemberArea(
          "member-area::tools::valuasi"
        ) && (
          <ToolsItemComponent
            imageUrl="https://api.midascuan.id/storage/resources/google-sheets-icon.png"
            text={"Valuasi"}
            showNewLabel={false}
            url={
              "https://docs.google.com/spreadsheets/d/1yvk-ip9x51lyK8zR88k_gSfM3I8ucFiOmzmALFcazgk"
            }
            showBetaLabel={false}
          />
        )} */}
      </div>
      <div className="h-4" />

      {UserSession.isUserCanAccessMemberArea(
        "member-area::tools::stock-treasure-web"
      ) && <TreasureMapsComponent showNewLabel={false} showBetaLabel={true} />}
    </div>
  );
};
