import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const UpdateCourseProgress: (
  courseItemId: number,
  progressPercent: number,
  progressSecond: number
) => Promise<string> = async (
  courseItemId,
  progressPercent,
  progressSecond
) => {
  try {
    let formData = new FormData();
    formData.append("progress_percent", progressPercent.toString());
    formData.append("progress_second", progressSecond.toString())

    let response = await client.post(
      ApiConst.API_COURSE_PROGRESS + "/" + courseItemId,
      formData
    );
    return response.data.data.message;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
