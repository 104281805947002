/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from "react";
import { FaUserCircle } from "react-icons/fa";
import { TestimoniCarouselModel } from "../models/carousel.model";

export type TestimoniCarouselProps = {
  carouselList: TestimoniCarouselModel[];
  focusedIndex: number;
  onClick?: (model: TestimoniCarouselModel, index: number) => void;
  getTranslatePosition: (index: number) => string;
  getScaleValue: (index: number) => string;
  heights: number[];
};

export const TestimoniCarouselComponent: FC<TestimoniCarouselProps> = ({
  carouselList,
  focusedIndex,
  onClick,
  getTranslatePosition,
  getScaleValue,
  heights,
}) => {
  return (
    <>
      {carouselList.map((item, index) => (
        <div
          key={item.key}
          className={`w-full h-fit my-auto md:w-1/3 transition-all duration-500 absolute bg-transparent ${
            focusedIndex === index ? "z-30" : "z-10"
          }`}
          style={{
            transform: `translateX(${getTranslatePosition(
              index
            )})  scale(${getScaleValue(index)})`,
            height: heights[index],
          }}
          onClick={() => onClick && onClick(item, index)}
        >
          <div className="relative w-full h-full">
            {item.imageUrl !== "" ? (
              <img
                className="rounded-full w-24 h-24 absolute m-auto top-0 right-0 left-0 z-30"
                src={item.imageUrl}
                alt=""
              />
            ) : (
              <div className="absolute top-0 right-0 left-0 z-30 text-white">
                <FaUserCircle size={96} className="m-auto" />
              </div>
            )}
            <div className="rounded-3xl absolute top-12 right-0 left-0 bottom-0 z-10 p-4 pt-16 text-center bg-black shadow shadow-slate-400">
              <div className="font-bold text-2xl text-white pt-5">{item.name}</div>
              <div className="font-normal text-lg color-white-70 mt-2">
                {item.title}
              </div>
              <div className="mt-2 color-white-70 font-normal text-lg">
                {item.testimoni}
              </div>
            </div>
            <div className="rounded-3xl color-yellow z-0 absolute top-16 right-0 left-0 md:-left-4 bottom-0 rotate-6 w-full"></div>
          </div>
        </div>
      ))}
    </>
  );
};
