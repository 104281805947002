import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const CheckIsAffiliateUsecase: () => Promise<boolean> = async () => {
  try {
    let response = await client.get(
      ApiConst.API_AFFILIATES + "/isAffiliateUser"
    );
    return response.data.data.isAffiliate === true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
