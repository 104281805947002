/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { GetPortfolioListUsecase } from "@Features/dashboard/domain/GetPortfolioList.usecase";
import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { PageModel } from "@Features/dynamic_page/data/Page.model";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaTelegram } from "react-icons/fa";
import { GroupItemComponent } from "./GroupsSection.component";
import { RecordItemListComponent } from "./RecordSection.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import { GetGroupListUsecase } from "@Features/dashboard/domain/GetGroupList.usecase";
import { PORTOFOLIO_GROUP } from "@Libraries/utils/Const";

export const PortfolioSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [portfolios, setPortfolios] = useState<PageModel[]>([]);
  const [pageSettings, setPageSettings] = useState<PageSettingListModel>();

  const toasterContext = UseToasterContext();

  useEffect(() => {
    setIsLoading(true);
    GetPortfolioListUsecase()
      .then((response) => {
        setPortfolios(response);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal memuat rekaman!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      })
      .finally(() => setIsLoading(false));
    GetGroupListUsecase()
      .then((response) => {
        setPageSettings(response);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal memuat group!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="mt-4">
      <Helmet encodeSpecialCharacters={true}>
        <title>Portfolio - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Portfolio" />
      </Helmet>
      {pageSettings && (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          <GroupItemComponent
            icon={<FaTelegram size={"50%"} />}
            text={"Grup Pengumuman Portfolio"}
            url={pageSettings[PORTOFOLIO_GROUP]}
            showNewLabel={false}
          />
        </div>
      )}
      {isLoading && (
        <div className="mt-4">
          <MidasShimmering />
        </div>
      )}
      {!isLoading && portfolios.length > 0 && (
        <div className="mt-4">
          <div className={`mt-4 grid grid-cols-1 gap-4`}>
            {portfolios.map((item, index) => {
              return (
                <RecordItemListComponent
                  key={`portfolio-item-list-${index}`}
                  title={item.title}
                  description=""
                  zoom_link=""
                  internalDestination={`/${item.slug}`}
                />
              );
            })}
          </div>
        </div>
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
