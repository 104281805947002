import { FC, MouseEventHandler } from "react";
import { Link } from "react-router-dom";

export type MidasRoundedYellowButtonProps = {
  label: string;
  additionalClass?: string;
  urlDestination?: string;
  onClick?: MouseEventHandler;
  isExternalLink: boolean;
  disabled?: boolean;
};

export const MidasRoundedYellowButton: FC<MidasRoundedYellowButtonProps> = ({
  label,
  additionalClass,
  urlDestination,
  isExternalLink,
  onClick,
  disabled
}) => {
  let defaultClass = "button-yellow";

  if (additionalClass !== null && additionalClass !== "") {
    defaultClass += " " + additionalClass;
  }

  const getButton = () => {
    return (
      <button className={defaultClass} onClick={onClick} disabled={disabled}>
        {label}
      </button>
    );
  };

  return urlDestination && isExternalLink === true ? (
    <a href={urlDestination} target="_blank" rel="noreferrer">
      {getButton()}
    </a>
  ) : urlDestination && isExternalLink !== true ? (
    <Link to={urlDestination}>{getButton()}</Link>
  ) : (
    getButton()
  );
};
