import { ProfileUiModel } from "@Features/profile/domain/model/Profile.ui.model";
import { FC, RefObject, useRef } from "react";
import { BsGearFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { MdCardMembership } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { ProfileSection } from "../Profile.page";
import { SidebarItem } from "./SidebarItem.component";
import { DividerComponent } from "@Libraries/components/divider/Divider.component";
import { UploadProfilePictUsecase } from "@Features/profile/domain/UploadProfilePict.usecase";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import {
  ToasterModel,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";

export type SidebarComponentProps = {
  networkData: ProfileUiModel | null;
  activeSection: ProfileSection;
  onChangeSection: (newSection: ProfileSection) => void;
  onLogout: () => void;
  setNeedRefresh: (needRefresh: boolean) => void;
};

export const SidebarComponent: FC<SidebarComponentProps> = ({
  networkData,
  activeSection,
  onChangeSection,
  onLogout,
  setNeedRefresh,
}) => {
  const toasterContext = UseToasterContext();

  const profilePictRef = useRef<HTMLInputElement>();

  const onChangeProfilePict = (e: any) => {
    let newPict = e.target.files.length > 0 ? e.target.files[0] : null;

    if (newPict !== null) {
      let formData = new FormData();
      formData.append("file", newPict);

      UploadProfilePictUsecase(formData)
        .then((response) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Foto profil berhasil di ubah.",
              "",
              ToasterType.SUCCESS
            ),
          ]);
          setNeedRefresh(true);
          try {
            e.target.reset();
          } catch (e) {}
        })
        .catch((errors) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Gagal memperbarui foto profil.",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        });
    }
  };

  return (
    <div className="user-profile-container">
      <div className="flex justify-center h-16">
        <div className="flex-none rounded-full bg-gray-400 text-center w-16 h-16 align-middle relative">
          {networkData?.pictureUrl !== null ? (
            <img
              src={networkData?.pictureUrl}
              alt=""
              className="object-cover w-16 h-16 rounded-full"
            />
          ) : (
            <FaUser size={40} color={"#FFFFFF"} className={"m-auto mt-2"} />
          )}
          <div
            className="bg-gray-500 rounded-b-full text-xs text-center text-white absolute bottom-0 w-full"
            onClick={() => profilePictRef.current?.click()}
          >
            <input
              className="w-full"
              style={{ display: "none" }}
              type={"file"}
              accept={"image/*"}
              onChange={onChangeProfilePict}
              ref={profilePictRef as RefObject<HTMLInputElement>}
            />
            Ubah
          </div>
        </div>
        <div className="ml-4 w-fit">
          <div className="text-xl font-bold overflow-hidden text-ellipsis">
            {networkData?.fullname}
          </div>
          <div className="text-base font-normal overflow-hidden text-ellipsis">
            {networkData?.email}
          </div>
        </div>
      </div>

      <DividerComponent />

      <SidebarItem
        icon={
          <ImProfile
            color={
              activeSection === ProfileSection.PROFILE ? "#FFDD28" : "#75767A"
            }
            size={28}
          />
        }
        text="Profil"
        onClick={() => onChangeSection(ProfileSection.PROFILE)}
      />

      <SidebarItem
        icon={
          <MdCardMembership
            color={
              activeSection === ProfileSection.MEMBERSHIP
                ? "#FFDD28"
                : "#75767A"
            }
            size={28}
          />
        }
        text="Membership"
        onClick={() => onChangeSection(ProfileSection.MEMBERSHIP)}
      />

      <SidebarItem
        icon={
          <CgNotes
            color={
              activeSection === ProfileSection.ORDERLIST ? "#FFDD28" : "#75767A"
            }
            size={28}
          />
        }
        text="Pesanan"
        onClick={() => onChangeSection(ProfileSection.ORDERLIST)}
      />

      <DividerComponent />

      <SidebarItem
        icon={<BsGearFill color="#75767A" size={28} />}
        text="Keluar"
        onClick={onLogout}
      />
    </div>
  );
};
