/* eslint-disable react-hooks/exhaustive-deps */
import { FooterComponent } from "@Libraries/components/footers/Footer.component";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AffiliateSidebarComponent } from "./sections/AffiliateDashboardSidebar.component";
import { CheckIsAffiliateUsecase } from "../domain/CheckIsAffiliate.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";

export const AffiliateDashboardPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAffiliate, setIsAffiliate] = useState<boolean>();

  useEffect(() => {
    if (!UserSession.checkIsLoggedIn()) {
      navigate("/login");
      return;
    }
    if (!UserSession.isUserCanAccessMemberArea()) {
      navigate("/login");
      return;
    }

    async function checkIsAffiliate() {
      let result = await CheckIsAffiliateUsecase();
      if (result !== true) {
        navigate("/affiliate-register");
        return;
      }
      setIsAffiliate(result);
    }

    checkIsAffiliate();
  }, [location.pathname]);

  return (
    <div className="w-screen bg-2e2e2e">
      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      <div>
        <div className="flex flex-col lg:flex-row">
          <div className="bg-midas-neutral-900 ">
            <AffiliateSidebarComponent />
          </div>
          <div className="w-full p-5 lg:p-10">
            {/* show content if have access */}
            {isAffiliate && (
              <div>
                <div className="text-white text-2xl font-semibold">
                  Hi, {UserSession.getName()}!
                </div>

                <Outlet />
              </div>
            )}

            {/* show register if doesn't registered yet */}
            {!isAffiliate && <MidasShimmering />}
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  );
};
