export function mapStatusToUi(status: string) {
  if (status === "waiting_for_payment") {
    return "Menunggu Pembayaran";
  }

  if (status === "in_progress") {
    return "Dalam Proses";
  }

  if (status === "paid") {
    return "Berhasil Dibayar";
  }

  if (status === "complete") {
    return "Selesai";
  }

  if (status === "failed") {
    return "Gagal";
  }

  if (status === "refunded") {
    return "Berhasil Refund";
  }

  return "";
}
