import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const GetTreasureMapsList: () => any = async () => {
  try {
    let response = await client.get(ApiConst.API_TREASURE_MAPS);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
