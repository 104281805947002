import { FC } from "react";

export type TabProps = {
  tabItems: string[];
  onClickTab: (index: number) => void;
  selectedIndex: number;
  isDisabled?: boolean;
};

export const Tabs: FC<TabProps> = ({
  tabItems,
  onClickTab,
  selectedIndex,
  isDisabled,
}) => {
  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-white border-b border-black overflow-x-auto">
      {tabItems.map(function (item, index) {
        return (
          <li
            className="mr-2"
            key={item}
            onClick={(e) => {
              onClickTab(index);
            }}
          >
            <span
              className={`inline-block p-4 rounded-t-lg ${
                index === selectedIndex
                  ? "text-black bg-white active rounded-t"
                  : "hover:text-gray-600 hover:bg-gray-50"
              } ${
                isDisabled &&
                "inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed"
              }`}
            >
              {item}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

Tabs.defaultProps = {
  selectedIndex: 0,
  isDisabled: false,
};
