import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { RecordModel } from "../data/record.model";

export const GetRecordListUsecase: () => Promise<RecordModel[]> = async () => {
  try {
    let response = await client.get(
      ApiConst.API_RECORD + "?status=published&limit=1000"
    );
    return response.data.data.list;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
