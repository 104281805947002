import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { MidasTestimoniComponent } from "@Features/common/presentation/components/MidasTestimoni.component";
import { MidasStockMembershipPriceComponent } from "@Libraries/components/prices/MidasStockMembershipPrice.component";
import { TextWithLeftImageComponent } from "@Libraries/components/text-image/TextWithLeftImage.component";
import { MidasVideoPlayer } from "@Libraries/components/video-player/VideoPlayer.component";
import {
  GENERAL_SELECTED_PRODUCTS,
  GENERAL_TESTIMONI,
  MEMBERSHIP_CAROUSEL,
  MEMBERSHIP_FAQ,
  MEMBERSHIP_FAQ_EMAIL,
  MEMBERSHIP_FINDING_STOCKS,
  MEMBERSHIP_HEADER_VIDEO,
  MEMBERSHIP_INTRODUCTION_VIDEO,
  MEMBERSHIP_SHOWCASE,
} from "@Libraries/utils/Const";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchMembershipSettingsUsecase } from "../domain/FetchMembershipSettings.usecase";
import { FAQSections } from "./components/FAQSections.component";
import { IntroductionSections } from "./components/IntroductionSections.component";
import { LinearCarouselSections } from "./components/LinearCarouselSections.component";
import { StockFindingSections } from "./components/StockFindingSections.component";
import { TopHeaderSections } from "./components/TopHeaderSections.component";
import { default as BukanStockPick } from "@Assets/midas-bukan-stock-pick.webp";
import { Helmet } from "react-helmet";
import { ShowcaseMembershipThumbnailSection } from "./components/ShowcaseMembershipThumbnailSection.component";
import { MediaSection } from "@Features/homepage/presentation/components/MediaSection.component";

export const MembershipPage: FC = () => {
  const navigate = useNavigate();
  const [pageSetting, setPageSetting] = useState<PageSettingListModel>({});

  const priceRef = useRef<null | HTMLElement>(null);

  useEffect(() => {
    FetchMembershipSettingsUsecase()
      .then((response) => {
        setPageSetting(response);
      })
      .catch((errors) => { });
  }, []);

  const onStartNowClicked = () => {
    navigate({
      hash: "start-now",
    });
    priceRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const getVideoUrl = () => {
    return pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO]
      ? JSON.parse(pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO]).videoUrl
      : "";
  };

  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <title>Membership - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Membership" />
      </Helmet>

      <section>
        <TopHeaderSections
          onClick={onStartNowClicked}
          data={
            pageSetting[MEMBERSHIP_HEADER_VIDEO]
              ? JSON.parse(pageSetting[MEMBERSHIP_HEADER_VIDEO])
              : { videoUrl: "" }
          }
        />
      </section>

      {pageSetting[GENERAL_TESTIMONI] && (
        <section>
          <MidasTestimoniComponent
            carouselId="membership-testimoni"
            data={JSON.parse(pageSetting[GENERAL_TESTIMONI])}
          />
        </section>
      )}

      {pageSetting[MEMBERSHIP_FINDING_STOCKS] && (
        <section>
          <StockFindingSections
            data={JSON.parse(pageSetting[MEMBERSHIP_FINDING_STOCKS])}
          />
        </section>
      )}

      <section>
        <MediaSection />
      </section>

      <section>
        <TextWithLeftImageComponent
          image={
            <MidasVideoPlayer
              componentKey="MidasCuan - Introduction"
              videoUrl={getVideoUrl()}
              options={{
                widthPercentage: 100,
                showVideoControls: true,
                isAutoPlay: true,
                canDownload: false,
                videoSource: "youtube",
                height: 400,
              }}
            />
          }
          showButton={true}
          buttonLabel={"Gabung sekarang"}
          onClick={onStartNowClicked}
          textChildren={
            <>
              <div className="responsive-header-title text-white font-extrabold text-left">
                Hanya 4 Menit dengan{" "}
                <div className="color-brand-yellow">Stocks Treasure Map</div>
              </div>
              <div className="text-xl text-white mt-6">
                Ini cara kita menemukan saham berkualitas di tiap sektor dengan
                tools Stock Treasure Maps (Excel seluruh saham di bursa)
                available khusus member.
              </div>
            </>
          }
        />
      </section>

      {/* <section>
        <IntroductionSections
          data={
            pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO]
              ? JSON.parse(pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO])
              : { videoUrl: "" }
          }
        />
      </section>
      {pageSetting[MEMBERSHIP_CAROUSEL] && (
        <section>
           <LinearCarouselSections
            data={JSON.parse(pageSetting[MEMBERSHIP_CAROUSEL])}
          />
        </section>
      )} */}

      {pageSetting[MEMBERSHIP_SHOWCASE] && (
        <section>
          <ShowcaseMembershipThumbnailSection
            data={JSON.parse(pageSetting[MEMBERSHIP_SHOWCASE])}
            onClick={onStartNowClicked}
          />
        </section>
      )}

      {pageSetting[GENERAL_SELECTED_PRODUCTS] && (
        <section id="start-now" ref={priceRef} className="mx-0 md:mx-48">
          <MidasStockMembershipPriceComponent
            data={JSON.parse(pageSetting[GENERAL_SELECTED_PRODUCTS])}
          />
        </section>
      )}

      <div className="mt-4">
        <img
          src={BukanStockPick}
          alt="MIDAS CUAN - Bukan Stock Pick"
          className="mx-auto w-full sm:w-1/2 pt-10"
        />
      </div>

      <section>
        <FAQSections
          data={
            pageSetting[MEMBERSHIP_FAQ]
              ? JSON.parse(pageSetting[MEMBERSHIP_FAQ])
              : []
          }
          emailData={
            pageSetting[MEMBERSHIP_FAQ_EMAIL]
              ? JSON.parse(pageSetting[MEMBERSHIP_FAQ_EMAIL])
              : { email: "" }
          }
        />
      </section>
      <section >
        <a 
          rel="noreferrer" 
          href="https://api.whatsapp.com/send?phone=6287884792402&text=Saya%20dari%20%20web%20midas%2C%20ingin%20mengetahui%20program%20midas%20stocks%20membership%20lebih%20lanjut%20🙏🏻" 
          target="_blank" 
          className="my-float"
        >
          <svg className="float" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
          </svg>
        </a>
      </section>
    </>
  );
};
