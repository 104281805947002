import { FC } from "react";

export type StockFindingModel = {
  title: string;
  description: string;
  imageUrl: string;
};

export type StockFindingItemProps = StockFindingModel & {
  number: string;
};

export type StockFindingProps = {
  data: StockFindingModel[];
};

export const StockFindingItemsComponent: FC<StockFindingItemProps> = ({
  number,
  title,
  description,
  imageUrl,
}) => {
  return (
    <div className="border rounded-2xl bg-white relative w-full h-full">
      <div className="absolute top-5 left-5 color-020202 font-extrabold opacity-50">
        {number}
      </div>
      <div className="flex flex-col justify-center p-6 pt-16 pb-8">
        <img
          className="mx-auto"
          src={imageUrl}
          alt=""
          width={100}
          height={100}
        />
        <div className="mt-6 text-2xl font-extrabold color-020202">{title}</div>
        <div className="mt-4 text-base font-medium color-midas-neutral-800 text-ellipsis overflow-hidden">
          {description}
        </div>
      </div>
    </div>
  );
};

export const StockFindingSections: FC<StockFindingProps> = ({ data }) => {
  return (
    <div className="mt-16 mx-7 md:mx-16 text-center">
      <div className="text-white font-bold text-5xl">
        Midas <span className="color-brand-yellow">Stocks Membership</span>
      </div>
      <div className="text-white text-xl font-normal mt-4">
        Akan membantu anda menemukan harta karun dengan cara:
      </div>
      <div
        className={`mt-12 md:mt-20 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3`}
      >
        {data.map((item, index) => {
          return (
            <StockFindingItemsComponent
              key={`stock-finding-${item.title.toLowerCase()}-${index}`}
              title={item.title}
              number={`0${index + 1}`}
              imageUrl={item.imageUrl}
              description={item.description}
            />
          );
        })}
      </div>
    </div>
  );
};
