import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AffiliateDashboardModel } from "../data/affiliate-dashboard.model";

export const GetAffiliateDashboardUsecase: () => Promise<AffiliateDashboardModel> =
  async () => {
    try {
      let response = await client.get(ApiConst.API_AFFILIATES + "/dashboard");
      let data = response.data.data;

      let last30DaysData = {
        visits: data.last_30_days.visits,
        visitChanges: data.last_30_days.visit_changes,
        atc: data.last_30_days.atc,
        atcChanges: data.last_30_days.atc_changes,
        complete: data.last_30_days.complete,
        completeChanges: data.last_30_days.complete_changes,
      };

      let allTimes = {
        visits: data.all_times.visits,
        atc: data.all_times.atc,
        complete: data.all_times.complete,
        convertion: data.all_times.convertion,
      };

      let earnings = {
        currentBalance: data.earnings.current_balance,
        onHoldBalance: data.earnings.on_hold_balance,
        withdrawnBalance: data.earnings.withdrawn_balance,
        totalEarnings: data.earnings.total_earnings,
      };

      return {
        affiliateName: data.affiliate_name,
        last30Days: last30DaysData,
        allTimes: allTimes,
        earnings: earnings,
      };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  };
