import {
  LoadPageSettingUsecase,
  PageSettingListModel,
} from "@Features/common/domain/LoadPageSettings.usecase";
import {
  AFFILIATE_TELEGRAM_GROUP,
  AFFILIATE_TUTORIAL_VIDEO_LINK,
} from "@Libraries/utils/Const";

export const GetAffiliateSettingsUsecase: () => Promise<PageSettingListModel> =
  async () => {
    let optionKeys = `${AFFILIATE_TUTORIAL_VIDEO_LINK};${AFFILIATE_TELEGRAM_GROUP}`;
    return await LoadPageSettingUsecase(optionKeys);
  };
