/* eslint-disable react-hooks/exhaustive-deps */
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { useEffect, useState } from "react";
import { TrackerDetailComponent } from "../components/TrackerDetail.component";
import { FaMoneyBill } from "react-icons/fa";
import { GetAffiliateDashboardUsecase } from "@Features/affiliate/domain/GetAffiliateDashboard.usecase";
import { AffiliateDashboardModel } from "@Features/affiliate/data/affiliate-dashboard.model";
import { GetAffiliateWithdrawUsecase } from "@Features/affiliate/domain/GetAffiliateWithdrawList.usecase";
import { AffiliateWithdrawModel } from "@Features/affiliate/data/affiliate-withdraw.model";
import { AffiliateWithdrawDetail } from "../components/AffiliateWithdrawDetail.component";
import { AffiliateRequestWithdrawModalComponent } from "../components/AffiliateRequestWithdrawModal.component";
import { GetBankListUseCase } from "@Features/affiliate/domain/GetBankList.usecase";
import { AffiliateBankModel } from "@Features/affiliate/data/affiliate-bank.model";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { RequestAffiliateWithdrawUseCase } from "@Features/affiliate/domain/RequestAffiliateWithdraw.usecase";
import { formatToCurrency } from "@Libraries/utils/Formatter";
import { Toaster } from "@Libraries/components/toast/Toast.component";

export const AffiliateWithdrawDashboardSection = () => {
  const toasterContext = UseToasterContext();

  const [isLoading, setIsLoading] = useState(true);
  const [needRefresh, setNeedRefresh] = useState(true);
  const [dashboardData, setDashboardData] = useState<AffiliateDashboardModel>();
  const [withdrawRequestList, setWithdrawRequestList] =
    useState<AffiliateWithdrawModel[]>();

  const [bankList, setBankList] = useState<AffiliateBankModel[]>();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [isRequestingWithdraw, setIsRequestingWithdraw] = useState(false);

  useEffect(() => {
    if (needRefresh) {
      fetchDashboardData();
      fetchWithdrawListData();
      setNeedRefresh(false);
    }
  }, [needRefresh]);

  useEffect(() => {
    if (showRequestModal && !bankList) {
      fetchBankListData();
    }
  }, [showRequestModal]);

  async function fetchDashboardData() {
    let data = await GetAffiliateDashboardUsecase();
    setDashboardData(data);
    setIsLoading(false);
  }

  async function fetchWithdrawListData() {
    setWithdrawRequestList(await GetAffiliateWithdrawUsecase());
  }

  async function fetchBankListData() {
    setBankList(await GetBankListUseCase());
  }

  async function onSubmitRequestWithdraw(amount: number, bank: number) {
    try {
      setIsRequestingWithdraw(true);
      let result = await RequestAffiliateWithdrawUseCase(amount, bank);
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(result, "", ToasterType.SUCCESS),
      ]);
    } catch (e) {
      setShowRequestModal(false);
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(e.message, "", ToasterType.DANGER),
      ]);
    } finally {
      setShowRequestModal(false);
      setIsRequestingWithdraw(false);
      setNeedRefresh(true);
    }
  }

  return (
    <div>
      {isLoading && <MidasShimmering />}

      {!isLoading && dashboardData && (
        <div>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="Total Earnings"
              subtitle={formatToCurrency(dashboardData.earnings.totalEarnings)}
            />
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="Current Balance"
              subtitle={formatToCurrency(dashboardData.earnings.currentBalance)}
            />
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="Withdrawn Balance"
              subtitle={formatToCurrency(
                dashboardData.earnings.withdrawnBalance
              )}
            />
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="On Hold Balance"
              subtitle={formatToCurrency(dashboardData.earnings.onHoldBalance)}
            />
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="mt-6 mb-6 text-white font-bold text-lg">
          <div>Withdraw Request List</div>
          <button
            className="mt-2 border border-brand-yellow rounded-lg text-[#ffdd28] px-5 py-2 d:px-6 md:py-2 text-sm font-bold hover:color-yellow hover:text-black"
            type="button"
            onClick={() => {
              setShowRequestModal(true);
            }}
          >
            Request Withdraw
          </button>
        </div>
      )}

      {!isLoading &&
        (!withdrawRequestList || withdrawRequestList.length === 0) && (
          <div className="text-center">Belum ada request withdraw.</div>
        )}

      {!isLoading &&
        withdrawRequestList &&
        withdrawRequestList.length > 0 &&
        withdrawRequestList.map((item, index) => (
          <AffiliateWithdrawDetail
            key={`withdraw-request-${index}-${item.id}`}
            {...item}
          />
        ))}

      {showRequestModal && (
        <AffiliateRequestWithdrawModalComponent
          showLoading={isRequestingWithdraw}
          availableAmount={dashboardData?.earnings.currentBalance ?? 0}
          bankList={bankList}
          onSubmit={onSubmitRequestWithdraw}
          onDismiss={() => {
            setShowRequestModal(false);
          }}
        />
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
