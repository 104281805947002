import {
  LoadPageSettingUsecase,
  PageSettingListModel,
} from "@Features/common/domain/LoadPageSettings.usecase";
import { DISCUSSION_GROUP, STRATEGY_ROOM, PORTOFOLIO_GROUP } from "@Libraries/utils/Const";

export const GetGroupListUsecase: () => Promise<PageSettingListModel> =
  async () => {
    let optionKeys = `${DISCUSSION_GROUP};${STRATEGY_ROOM};${PORTOFOLIO_GROUP}`;
    return await LoadPageSettingUsecase(optionKeys);
  };
