import { FC } from "react";
import { FaCopy } from "react-icons/fa";

export type TableProps = {
  title: string;
  label: string[];
  data: any[][];
  freezeColumn: boolean;
};

export const TableComponent: FC<TableProps> = ({ title, label, data, freezeColumn=true }) => {
  if (label.length === 0 || data.length === 0) {
    return (
      <div className="m-auto text-xl text-gray-500 bg-white">
        Data Not Available
      </div>
    );
  }

  return (
    <div className="bg-white text-black rounded">
      {title !== "" && (
        <div className="font-bold text-base text-center">{title}</div>
      )}
      <div className="overflow-x-auto text-lg relative tscroll">
        <table
          className={`border-collapse border w-full ${
            title !== "" ? "mt-4 " : ""
          }`}
        >
          <thead>
            <tr>
              {label.map((item, index) => (
                <td
                  key={`${title}-${item}-${index}`}
                  className="border border-spacing-1 text-center font-bold px-2"
                >
                  {item}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={`${title}-data-row-${row[0]}-${index}`}>
                {row[1].trim() === "" && row[2].trim() === "" ? (
                  <td
                    key={`${title}-data-column-${row[0]}-0`}
                    className={`border px-2 text-left font-bold py-2 color-brand-yellow bg-black`}
                    colSpan={row.length}
                  >
                    {row[0]}
                  </td>
                ) : (
                  row.map((column, columnIndex) => (
                    <td
                      key={`${title}-data-column-${column}-${columnIndex}`}
                      className={`border px-2 ${
                        columnIndex === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      {columnIndex !==0 
                        ? column
                        : <div>
                          {column}
                          <button
                            className="pl-4"
                            onClick={() => {
                              navigator.clipboard.writeText(column);
                            }}
                          >
                            <FaCopy size={24} />
                          </button> 
                        </div>
                      }
                    </td>
                  ))
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
