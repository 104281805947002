import { default as StockKeyInsightTopHeader } from "@Assets/stock-insight/top-header.png";
import { FC } from "react";
import { TextWithRightImageComponent } from "@Libraries/components/text-image/TextWithRightImage.component";

export const TopHeaderSections: FC = () => {
  return (
    <TextWithRightImageComponent
      textChildren={
        <>
          <div className="responsive-header-title text-white font-bold">
            Analisa emiten secara detail
            <div className="color-brand-yellow font-black">
              dengan MIDAS Stock Key Insight
            </div>
          </div>
          <div className="font-normal text-xl color-white-70 mt-6 text-left">
            At Finance we care about your future. We help you invest the way you
            want. So you can relax, have fun and let your fund grow.
          </div>
        </>
      }
      image={
        <img
          src={StockKeyInsightTopHeader}
          alt="MidasCuan - Analisa emiten secara detail dengan MIDAS Stock Key Insight"
        />
      }
      showButton={true}
      buttonLabel={"Mulai sekarang"}
      destination="/membership"
    />
  );
};
