export const ThreeColumnSections = () => {
  return (
    <div className="mx-7 mt-7 md:mx-28 md:mt-14 grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-y-0 divide-x-0 md:divide-x-2 font-medium text-white text-center">
      <div className="py-3 md:py-0 px-0 md:px-5">
        Hasil analisa lebih detail, tepat, dan akurat
      </div>
      <div className="py-3 md:py-0 px-0 md:px-5">
        Menghemat waktu Anda sehingga lebih efiektif
      </div>
      <div className="py-3 md:py-0 px-0 md:px-5">
        Tidak perlu khawatir hasil analisa salah dan tidak akurat
      </div>
    </div>
  );
};
