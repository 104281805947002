import { FC, ReactNode } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export type TrackerDetailProps = {
  icon: ReactNode;
  title: string;
  subtitle: string;
  changes?: number;
};

export const TrackerDetailComponent: FC<TrackerDetailProps> = ({
  icon,
  title,
  subtitle,
  changes,
}) => {
  return (
    <div>
      <div className="rounded border p-4 bg-slate-100 mt-2 shadow">
        <div className="flex">
          {icon}
          <div className="w-4"></div>
          <div>
            <div>{title}</div>
            <div className="flex text-5xl text-ellipsis">
              {subtitle}{" "}
              {changes !== undefined && (
                <span
                  className={`ml-2 my-auto text-lg flex ${
                    changes > 0
                      ? "text-green-500"
                      : changes < 0
                      ? "text-red-500"
                      : "color-brand-yellow"
                  }`}
                >
                  {changes > 0 ? (
                    <FaArrowUp size={16} className="my-auto mr-1" />
                  ) : changes < 0 ? (
                    <FaArrowDown size={16} className="my-auto mr-1" />
                  ) : (
                    <></>
                  )}{" "}
                  {changes} %
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
