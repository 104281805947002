/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import {
  LinearImageCarouselComponent,
  LinearImageTeaserComponent,
} from "./items/LinearImageCarousel.component";
import { LinearTextCarouselComponent } from "./items/LinearTextCarousel.component";
import {
  CarouselItemModel,
  CarouselListModel,
  CarouselType,
} from "./models/carousel-list.model";
import { LinearTextCarouselModel } from "./models/carousel.model";

export type AutoPlayCarouselProps = CarouselListModel & {
  onClick?: (model: CarouselItemModel, index: number) => void;
  duration: number;
  onNext: () => void;
  onPrev: () => void;
};

export const AutoPlayCarouselComponent: FC<AutoPlayCarouselProps> = ({
  carouselId,
  carouselList,
  focusedIndex,
  duration,
  onNext,
  type,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [componentHeight, setComponentHeight] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      onNext();
    }, duration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    updateHeight();

    async function updateAfterDelay() {
      setTimeout(() => {
        updateHeight();
      }, 2500);
    }

    updateAfterDelay();
  }, [ref]);

  const updateHeight = () => {
    if (ref.current) {
      let longestHeight = 0;

      ref.current.childNodes.forEach((node, index) => {
        let parsed = node as HTMLDivElement;
        if (parsed.scrollHeight > longestHeight) {
          longestHeight = parsed.scrollHeight + 16;
        }
      });

      if (longestHeight < 100) {
        longestHeight = 400;
      }

      setComponentHeight(longestHeight);
    }
  };

  const getTranslatePosition: (index: number) => string = (index) => {
    let additionalValue = 10;

    if (window.innerWidth < 768) {
      additionalValue = 0;
    }

    let currentPosition = index - focusedIndex;
    currentPosition++;

    if (currentPosition === 0) {
      return `-${additionalValue}%`;
    }

    if (currentPosition === 2) {
      return (currentPosition * 100 + additionalValue).toString() + "%";
    }

    if (currentPosition < 0) {
      currentPosition = index + carouselList.length - focusedIndex + 1;
      if (currentPosition < 0) {
        currentPosition *= -1;
      }
      return (currentPosition * 100 + additionalValue).toString() + "%";
    }

    if (currentPosition >= carouselList.length) {
      currentPosition -= carouselList.length;
      return (currentPosition * 100 - additionalValue).toString() + "%";
    }

    return (currentPosition * 100).toString() + "%";
  };

  const getScaleValue: (index: number) => string = (index) => {
    return "1";
  };

  return (
    <div className="grid place-items-center" id={carouselId}>
      <div
        ref={ref}
        className={`w-full relative overflow-hidden`}
        style={{
          height: componentHeight,
        }}
      >
        {type === CarouselType.LINEAR ? (
          <LinearImageCarouselComponent
            carouselList={carouselList}
            focusedIndex={focusedIndex}
            getScaleValue={getScaleValue}
            getTranslatePosition={getTranslatePosition}
            additionalInfo=""
          />
        ) : type === CarouselType.LINEAR_TEXT ? (
          <LinearTextCarouselComponent
            carouselList={carouselList as LinearTextCarouselModel[]}
            focusedIndex={focusedIndex}
            getScaleValue={getScaleValue}
            getTranslatePosition={getTranslatePosition}
            height={componentHeight}
          />
        ) : type === CarouselType.LINEAR_TEASER ? (
          <LinearImageTeaserComponent
            carouselList={carouselList}
            focusedIndex={focusedIndex}
            getScaleValue={getScaleValue}
            getTranslatePosition={getTranslatePosition}
            additionalInfo=""
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
