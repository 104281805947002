/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { OfferModel } from "@Features/dashboard/data/offer.model";
import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { UserSession } from "@Libraries/users/UserSession";
import {
  MEMBER_OFFER_MEMBER_AND_PORTOFOLIO,
  MEMBER_OFFER_MEMBER_ONLY,
  MEMBER_OFFER_PORTOFOLIO_ONLY,
} from "@Libraries/utils/Const";
import { Toaster } from "@Libraries/components/toast/Toast.component";

export const ExtendSection = () => {
  const [offerList, setOfferList] = useState<OfferModel[]>([]);

  const toasterContext = UseToasterContext();

  useEffect(() => {
    async function fetchData() {
      try {
        let key = "";

        if (
          UserSession.isUserCanAccessMemberArea("member-area::portfolio") &&
          UserSession.isUserCanAccessMemberArea("member-area::courses")
        ) {
          key = MEMBER_OFFER_MEMBER_AND_PORTOFOLIO;
        } else if (
          UserSession.isUserCanAccessMemberArea("member-area::portfolio")
        ) {
          key = MEMBER_OFFER_PORTOFOLIO_ONLY;
        } else if (
          UserSession.isUserCanAccessMemberArea("member-area::courses")
        ) {
          key = MEMBER_OFFER_MEMBER_ONLY;
        }

        if (key === "") return;

        let response = await LoadPageSettingUsecase(key);
        let dataString = response[key];

        setOfferList(JSON.parse(dataString));
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal mengambil data Offer",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="mt-4">
      <Helmet encodeSpecialCharacters={true}>
        <title>Perpanjang - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Perpanjang" />
      </Helmet>

      {offerList.length === 0 && <MidasShimmering />}

      {offerList.length > 0 && (
        <div className={`mt-4 grid grid-cols-1 gap-4`}>
          {offerList.map((item, index) => {
            return (
              <div
                className="w-full flex justify-between gap-4 rounded-3xl bg-midas-neutral-900 text-white p-4"
                key={`offer-item-${index}`}
              >
                <div className="flex gap-4">
                  <div className="text-xl font-bold my-auto">
                    {item.productName}
                  </div>
                  {item.showSale && (
                    <div className="my-auto text-lg text-red-500">SALE </div>
                  )}
                </div>

                <Link
                  className="button-yellow"
                  to={`/add-to-cart?product=${item.productId}`}
                  target="_blank"
                >
                  Perpanjang Sekarang
                </Link>
              </div>
            );
          })}
        </div>
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
