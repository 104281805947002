import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import {
  PHILOSOPHY_CRITERIA,
  PHILOSOPHY_TOP_HEADER,
} from "@Libraries/utils/Const";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FetchInvestmentPhilosophySettingsUsecase } from "../domain/FetchInvestmentPhilosophySettings.usecase";
import { CriteriaCarouselSection } from "./components/CriteriaSection.component";
import { QuoteSection } from "./components/QuoteSection.component";
import { RiskSection } from "./components/RiskSection.component";
import { TopHeaderSections } from "./components/TopHeaderSections.component";

export const InvestmentPhilosophyPage = () => {
  const [pageSetting, setPageSetting] = useState<PageSettingListModel>({});

  useEffect(() => {
    FetchInvestmentPhilosophySettingsUsecase()
      .then((response) => {
        setPageSetting(response);
      })
      .catch((errors) => {});
  }, []);

  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <title>Investment Philosophy - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Investment Philosophy" />
      </Helmet>

      <section>
        <TopHeaderSections
          data={
            pageSetting[PHILOSOPHY_TOP_HEADER]
              ? JSON.parse(pageSetting[PHILOSOPHY_TOP_HEADER])
              : { imageUrl: "", altText: "" }
          }
        />
      </section>
      {pageSetting[PHILOSOPHY_CRITERIA] && (
        <section>
          <CriteriaCarouselSection
            data={JSON.parse(pageSetting[PHILOSOPHY_CRITERIA])}
          />
        </section>
      )}
      <section>
        <RiskSection />
      </section>
      <section>
        <QuoteSection />
      </section>
      <div className="mt-14 md:mt-28">
        <div className="mx-20 md:mx-40 text-lg font-medium text-white text-left">
          Dengan menerapkan metode yang kita jabarkan ini, seperti yang sudah
          diterapkan oleh banyak investor besar lainnya, kita berharap bisa
          menghasilkan "compounding machine" yang mampu menumbuhkan portfolio
          investasi kita dalam jangka panjang. Inilah saham harta karun yang
          kita maksud.
        </div>
      </div>
    </>
  );
};
