import { FC } from "react";
import { FaCartPlus, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CarouselModel } from "../models/carousel.model";

export type LinearImageCarouselProps = {
  carouselList: CarouselModel[];
  focusedIndex: number;
  additionalInfo?: string;
  onClick?: (model: CarouselModel, index: number) => void;
  getTranslatePosition: (index: number) => string;
  getScaleValue: (index: number) => string;
};

export const LinearImageCarouselComponent: FC<LinearImageCarouselProps> = ({
  carouselList,
  additionalInfo,
  getScaleValue,
  getTranslatePosition,
  onClick,
}) => {
  const buildImage = (imageUrl: string, altText: string) => {
    return (
      <img
        src={imageUrl}
        className={`w-full h-full object-cover rounded-2xl`}
        alt={altText}
      />
    );
  };

  return (
    <>
      {carouselList.map((item, index) => (
        <div
          key={item.key}
          className={`w-full ${additionalInfo} md:w-1/3 absolute transition-all duration-500 py-4`}
          style={{
            transform: `translateX(${getTranslatePosition(
              index
            )}) scale(${getScaleValue(index)})`,
          }}
          onClick={() => onClick && onClick(item, index)}
        >
          {item.productId ? (
            <Link to={`/add-to-cart?product=${item.productId}`}>
              {buildImage(item.imageUrl, item.altText)}
            </Link>
          ) : (
            buildImage(item.imageUrl, item.altText)
          )}
        </div>
      ))}
    </>
  );
};

export const LinearImageTeaserComponent: FC<LinearImageCarouselProps> = ({
  carouselList,
  additionalInfo,
  getScaleValue,
  getTranslatePosition,
}) => {
  const buildImage = (imageUrl: string, altText: string) => {
    return (
      <img
        src={imageUrl}
        className={`w-full h-full object-cover rounded-t-2xl`}
        alt={altText}
      />
    );
  };

  return (
    <>
      {carouselList.map((item, index) => (
        <div
          key={item.key}
          className={`w-full ${additionalInfo} md:w-1/3 absolute transition-all duration-500 py-4`}
          style={{
            transform: `translateX(${getTranslatePosition(
              index
            )}) scale(${getScaleValue(index)})`,
          }}
        >
          {buildImage(item.imageUrl, item.altText)}
          <div className="absolute bottom-0 left-0 right-0 rounded-b-2xl grid grid-cols-2 divide-x">
            <a
              href={item.teaserUrl}
              rel={"noreferrer"}
              target={"_blank"}
              className="flex justify-center color-brand-yellow hover:text-white bg-black hover:color-yellow bg-opacity-50 hover:bg-opacity-90 p-2 rounded-bl-2xl"
            >
              <FaEye />
            </a>

            <Link
              to={
                item.productId ? `/add-to-cart?product=${item.productId}` : "#"
              }
              className="flex justify-center color-brand-yellow hover:text-white bg-black hover:color-yellow bg-opacity-50 hover:bg-opacity-90 p-2 rounded-br-2xl"
            >
              <FaCartPlus />
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};
