import { GetAffiliateSettingsUsecase } from "@Features/affiliate/domain/GetAffiliateSettings.usecase";
import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { GroupItemComponent } from "@Features/dashboard/presentation/sections/GroupsSection.component";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { MidasVideoPlayer } from "@Libraries/components/video-player/VideoPlayer.component";
import {
  AFFILIATE_TELEGRAM_GROUP,
  AFFILIATE_TUTORIAL_VIDEO_LINK,
} from "@Libraries/utils/Const";
import { useEffect, useState } from "react";
import { FaTelegram } from "react-icons/fa";

export const AffiliateSettingsSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSettings, setPageSettings] = useState<PageSettingListModel>();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      let response = await GetAffiliateSettingsUsecase();
      setPageSettings(response);
    } catch (e) {
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      {isLoading && <MidasShimmering />}

      {!isLoading && !pageSettings && (
        <div className="text-center font-bold text-white">
          Data Settings tidak tersedia.
        </div>
      )}

      {!isLoading && pageSettings && (
        <div
          className={`mx-auto ${window.screen.width <= 500 ? "w-full" : "w-2/3"
            }`}
        >
          {pageSettings[AFFILIATE_TUTORIAL_VIDEO_LINK] !== "" && (
            <MidasVideoPlayer
              componentKey="affiliate-tutorial-video"
              videoUrl={pageSettings[AFFILIATE_TUTORIAL_VIDEO_LINK]}
              options={{
                widthPercentage: window.screen.width <= 500 ? 90 : 75,
                showVideoControls: true,
                isAutoPlay: false,
                canDownload: false,
                videoSource: "youtube",
              }}
            />
          )}

          {pageSettings[AFFILIATE_TELEGRAM_GROUP] !== "" && (
            <div className="mx-auto w-96 mt-4">
              <GroupItemComponent
                icon={<FaTelegram size={"50%"} />}
                text={"Grup Midas Refferal Support"}
                url={pageSettings[AFFILIATE_TELEGRAM_GROUP]}
                showNewLabel={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
