import { default as MidasHomepageTopImage } from "@Assets/homepage/midas-homepage-top.png";
import { ClickListenerType } from "@Features/common/presentation/listener/Click.listener";
import { TextWithRightImageComponent } from "@Libraries/components/text-image/TextWithRightImage.component";
import { FC } from "react";

export const TopHeaderSection: FC<ClickListenerType> = ({ onClick }) => {
  return (
    <TextWithRightImageComponent
      textChildren={
        <>
          <div className="responsive-header-title text-white font-bold">
            Menjadi Investor
            <div className="color-brand-yellow font-black">
              “Pro, Independent, dan Confident”
            </div>
          </div>
          <div className="font-normal text-xl color-white-70 mt-6 text-left">
            Persingkat waktu anda, dengan belajar langsung dari real investor
            saham sejak 2014 yang sudah menginvestasikan &gt;90% dari total net
            worth nya di pasar saham
          </div>
        </>
      }
      image={
        <img
          src={MidasHomepageTopImage}
          alt="MidasCuan - Menjadi Investor “Pro, Independent, dan Confident”"
        />
      }
      showButton={true}
      buttonLabel={"Mulai sekarang"}
      onClick={onClick}
    />
  );
};
