import { FC, ReactElement } from "react";

export type SidebarItemProp = {
  icon: ReactElement;
  text: string;
  onClick: VoidFunction;
};

export const SidebarItem: FC<SidebarItemProp> = ({ icon, text, onClick }) => {
  return (
    <button className="flex mt-6" onClick={onClick}>
      {icon}
      <div className="ml-3 my-auto text-base font-medium">{text}</div>
    </button>
  );
};
