/* eslint-disable react-hooks/exhaustive-deps */
import { EyeIcon, EyeSlashIcon } from "@Assets/common.assets";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { EMAIL_REGEX } from "@Libraries/utils/Regex";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ResetPasswordUsecase } from "../domain/ResetPassword.usecase";

type ForgotPasswordFormInputs = {
  email: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const ResetPasswordPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>();

  useEffect(() => {
    if (UserSession.checkIsLoggedIn()) {
      navigate("/");
    }
  }, []);

  const onSubmit = async (data: ForgotPasswordFormInputs) => {
    try {
      setSuccessMessage(
        await ResetPasswordUsecase(
          data.email,
          data.newPassword,
          data.newPasswordConfirmation,
          searchParams.get("token") ?? ""
        )
      );
      setErrorMessage("");

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error: any) {
      setErrorMessage(error.message);
      setSuccessMessage("");
    }
  };

  return (
    <div className="bg-midas-neutral-900 h-screen overflow-scroll">
      <HeaderWithMenu
        shouldShowLoginButton={false}
        menuItems={getSiteMenus()}
      />
      <div className="h-full flex justify-evenly">
        <div className="w-full h-full hidden lg:block text-white"></div>
        <div className="w-full h-full text-white">
          <form
            className="m-6 w-fit mx-auto lg:my-auto lg:mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mt-8 font-bold text-3xl">Reset Password</div>

            {/* error message */}
            {successMessage !== "" && (
              <div className="w-full mt-6 rounded p-2 text-white text-xs bg-green-500 text-justify">
                {successMessage}
              </div>
            )}

            {/* error message */}
            {errorMessage !== "" && (
              <div className="w-full mt-6 rounded p-2 text-white text-xs bg-red-500 text-justify">
                {errorMessage}
              </div>
            )}

            <div className="mt-8 flex flex-col">
              <label className="font-normal">Email</label>
              <input
                type="text"
                className="input-form"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid email address.",
                  },
                })}
              />
              {errors.email && (
                <p className="text-red-500 text-xs">{errors.email.message}</p>
              )}
            </div>

            <div className="mt-8 flex flex-col">
              <label className="font-normal">Password Baru</label>
              <div className="flex w-96 border border-282828 rounded-md px-6 py-4 bg-282828 mt-3 text-sm">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full bg-282828 outline-none"
                  placeholder="Enter your password"
                  {...register("newPassword", {
                    required: "Password is required.",
                  })}
                />
                <div
                  className="w-6 h-6 color-brand-yellow"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {errors.newPassword && (
                <p className="text-red-500 text-xs">
                  {errors.newPassword.message}
                </p>
              )}
            </div>

            <div className="mt-8 flex flex-col">
              <label className="font-normal">Konfirmasi Password Baru</label>
              <div className="flex w-96 border border-282828 rounded-md px-6 py-4 bg-282828 mt-3 text-sm">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full bg-282828 outline-none"
                  placeholder="Enter your password"
                  {...register("newPasswordConfirmation", {
                    required: "Password is required.",
                  })}
                />
              </div>
              {errors.newPasswordConfirmation && (
                <p className="text-red-500 text-xs">
                  {errors.newPasswordConfirmation.message}
                </p>
              )}
            </div>

            <button className="button-yellow mt-8 w-full">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
