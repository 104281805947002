import { AffiliateBankModel } from "@Features/affiliate/data/affiliate-bank.model";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { formatToCurrency } from "@Libraries/utils/Formatter";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";

export type AffiliateRequestWithdrawModalProps = {
  showLoading: boolean;
  availableAmount: number;
  bankList?: AffiliateBankModel[];
  onSubmit: (amount: number, bank: number) => void;
  onDismiss: () => void;
};

export type AffiliateRequestWithdrawFormInputs = {
  amount: number;
  bank: number;
};

export const AffiliateRequestWithdrawModalComponent: FC<
  AffiliateRequestWithdrawModalProps
> = ({ showLoading, availableAmount, bankList, onSubmit, onDismiss }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<AffiliateRequestWithdrawFormInputs>();

  function onLocalSubmit(data: AffiliateRequestWithdrawFormInputs) {
    if (!data.bank) {
      setError(
        "bank",
        {
          message: "Bank account is required",
        },
        {
          shouldFocus: true,
        }
      );
      return;
    }
    onSubmit(data.amount, data.bank);
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-screen w-screen">
      <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <h3 className="text-lg leading-6 font-bold text-gray-900 flex justify-between">
          <div>Request Withdraw</div>
          <AiOutlineCloseCircle color="red" size={24} onClick={onDismiss} />
        </h3>
        <span className="text-gray-500 text-sm">Min. Rp100.000</span>
        <div className="mt-2 pb-3">
          {showLoading && <MidasShimmering />}
          {!showLoading && (
            <form onSubmit={handleSubmit(onLocalSubmit)}>
              <div className="text-form-label mt-6">
                Amount{" "}
                <span className="font-semibold text-gray-500">
                  (Available : {formatToCurrency(availableAmount)})
                </span>
              </div>
              <div>
                <input
                  type="number"
                  className="form-input"
                  style={{
                    padding: 10,
                  }}
                  placeholder="Enter amount to withdraw"
                  {...register("amount", {
                    required: "Amount is required",
                    max: {
                      value: availableAmount,
                      message: `Maximum amount is ${formatToCurrency(
                        availableAmount
                      )}`,
                    },
                    min: {
                      value: 100000,
                      message: "Minimum amount is Rp100.000",
                    },
                  })}
                />
                {errors.amount && (
                  <p className="text-red-500 text-xs">
                    {errors.amount?.message}
                  </p>
                )}
              </div>

              {bankList && (
                <div>
                  <div className="text-form-label mt-2">Bank</div>
                  <div>
                    <Select
                      className="basic-single text-black"
                      isSearchable={true}
                      options={bankList.map((item) => {
                        return {
                          value: item.id,
                          label: `${item.bankName.toUpperCase()} - ${item.bankHolderName
                            } - ${item.bankAccountNumber}`,
                        };
                      })}
                      onChange={(newValue) => {
                        if (newValue) setValue("bank", newValue.value);
                      }}
                    />
                    {errors.bank && (
                      <p className="text-red-500 text-xs">
                        {errors.bank?.message}
                      </p>
                    )}
                  </div>
                </div>
              )}

              <button type="submit" className="w-full mt-4 bg-[#ffdd28] rounded-lg">
                Ajukan Withdraw
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
