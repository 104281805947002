import { ClickListenerType } from "@Features/common/presentation/listener/Click.listener";
import { FC } from "react";

export type TopHeaderModel = {
  imageUrl: string;
  altText: string;
};

export type TopHeaderProps = ClickListenerType & {
  data: TopHeaderModel;
};

export const TopHeaderSections: FC<TopHeaderProps> = ({ data, onClick }) => {
  return (
    <div className="mt-7 mx-7 md:mx-28 md:mt-13 text-left">
      <div className="mx-0 md:mx-4 responsive-header-title  text-white font-black text-center">
        Our <span className="color-brand-yellow">Investment Philosophy</span>
      </div>
      <div className="mt-6 color-white-70 text-center font-normal text-xl">
        Kita tidak hanya fokus "yang penting cuan", tapi kita juga fokus pada
        CARA untuk mendapatkan cuan itu. Jika untuk mendapatkan cuan itu
        memerlukan mengambil risiko yang amat besar, kita lebih baik skip dan
        mencari peluang baru yang lebih masuk akal.
      </div>
      {data.imageUrl !== "" && (
        <div className="mt-8 flex justify-center">
          <img
            src={data.imageUrl}
            alt={data.altText}
            className="rounded-3xl w-full"
          />
        </div>
      )}
    </div>
  );
};
