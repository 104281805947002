import { MidasLogo } from "@Assets/midas-logo";
import { ChartData, ChartOptions } from "chart.js";
import { FC, useRef } from "react";
import { Bar, Line, Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { BiReset, BiZoomIn, BiZoomOut } from "react-icons/bi";
import { ChartButtonComponent } from "./ChartButton.component";

export enum MidasChartType {
  LINE,
  BAR,
  MULTIPLE
}

export type ChartProps = {
  type: MidasChartType;
  options: ChartOptions<any>;
  data?: ChartData<any> | null;
};

export const MidasChart: FC<ChartProps> = ({ data, options, type }) => {
  const lineChartRef = useRef<ChartJSOrUndefined<"line", any[], unknown>>(undefined);
  const barChartRef = useRef<ChartJSOrUndefined<"bar", any[], unknown>>(undefined);
  const otherChartRef = useRef<ChartJSOrUndefined<"scatter", any[], unknown>>(undefined);

  if (data === undefined || data === null) {
    return (
      <div className="m-auto text-xl text-gray-500">Data Not Available</div>
    );
  }

  return (
    <div className="relative">
      <div className="absolute top-[40%] left-[40%] text-white m-auto text-4xl">
        <MidasLogo className="opacity-30 object-cover" />
      </div>

      {data && type === MidasChartType.LINE ? (
        <Line ref={lineChartRef} options={options} data={data} />
      ) : data && type === MidasChartType.BAR ? (
        <Bar ref={barChartRef} options={options} data={data} />
      ) : (
        <Chart type='bar' ref={otherChartRef} options={options} data={data} />
      )}

      <div
        className="flex justify-between mx-4
    "
      >
        <div className="text-white text-xs my-auto">
          *Gunakan Ctrl + Scroll untuk Zoom.
        </div>
        <div className="flex gap-4">
          <ChartButtonComponent
            icon={<BiZoomIn size={24} color="white" />}
            onClick={() => {
              if (type === MidasChartType.LINE && lineChartRef.current) {
                lineChartRef.current.zoom(1.1);
              } else if (type === MidasChartType.BAR && barChartRef.current) {
                barChartRef.current.zoom(1.1);
              } else if (type === MidasChartType.MULTIPLE && otherChartRef.current) {
                otherChartRef.current.zoom(1.1);
              }
            }}
          />
          <ChartButtonComponent
            icon={<BiZoomOut size={24} color="white" />}
            onClick={() => {
              if (type === MidasChartType.LINE && lineChartRef.current) {
                lineChartRef.current.zoom(0.9);
              } else if (type === MidasChartType.BAR && barChartRef.current) {
                barChartRef.current.zoom(0.9);
              } else if (type === MidasChartType.MULTIPLE && otherChartRef.current) {
                otherChartRef.current.zoom(0.9);
              }
            }}
          />
          <ChartButtonComponent
            icon={<BiReset size={24} color="white" />}
            onClick={() => {
              if (type === MidasChartType.LINE && lineChartRef.current) {
                lineChartRef.current.resetZoom();
              } else if (type === MidasChartType.BAR && barChartRef.current) {
                barChartRef.current.resetZoom();
              } else if (type === MidasChartType.MULTIPLE && otherChartRef.current) {
                otherChartRef.current.resetZoom();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
