/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import {
  CheckoutFormPage,
  CheckoutState,
} from "@Features/checkout/presentation/CheckoutForm.page";
import { LoginPage } from "@Features/authentication/presentation/Login.page";
import { HomePage } from "@Features/homepage/presentation/Home.page";
import { ProfilePage } from "@Features/profile/presentation/Profile.page";
import { StartingGuideSection } from "@Features/dashboard/presentation/sections/StartingGuideSection.component";
import { Page } from "@Features/dynamic_page/presentation/Page.page";
import { BasePage } from "@Features/common/presentation/BasePage.page";
import { MembershipPage } from "@Features/membership/presentation/Membership.page";
import { StockKeyInsightPage } from "@Features/stock-key-insight/presentation/StockKeyInsight.page";
import { InvestmentPhilosophyPage } from "@Features/investment-philosophy/presentation/InvestmentPhilosophy.page";
import { InvestmentPhilosophySection } from "@Features/dashboard/presentation/sections/InvestmentPhilosophySection.component";
import { ToolsSection } from "@Features/dashboard/presentation/sections/ToolsSection.component";
import { GroupDiscussionSection } from "@Features/dashboard/presentation/sections/GroupsSection.component";
import { useEffect } from "react";
import { UserSession } from "@Libraries/users/UserSession";
import { CoursesSection } from "@Features/dashboard/presentation/sections/CoursesSection.component";
import { JarvisSection } from "@Features/dashboard/presentation/sections/JarvisSection.component";
import { AnalysisSection } from "@Features/dashboard/presentation/sections/AnalysisSection.component";
import { RecordSection } from "@Features/dashboard/presentation/sections/RecordSection.component";
import { GetProfileUsecase } from "@Features/profile/domain/GetProfile.usecase";
import { CourseDetailPage } from "@Features/course/presentation/CourseDetail.page";
import { CuanCalculatorPage } from "@Features/cuan-calculator/presentation/CuanCalculator.page";
import { NotFoundPage } from "@Features/common/presentation/NotFound.page";
import { PortfolioSection } from "@Features/dashboard/presentation/sections/PortfolioSection.component";
import { ForgotPasswordPage } from "@Features/authentication/presentation/ForgotPassword.page";
import { ResetPasswordPage } from "@Features/authentication/presentation/ResetPassword.page";
import { Helmet } from "react-helmet";
import { ExtendSection } from "@Features/dashboard/presentation/sections/ExtendSection.component";
import { UpgradeSection } from "@Features/dashboard/presentation/sections/UpgradeSection.component";

import "handsontable/dist/handsontable.full.min.css";
import { useCookies } from "react-cookie";
import {
  AFFILIATE_DAY_DURATION,
  KEY_AFFILIATE_ID,
  KEY_CHECKOUT,
} from "@Libraries/utils/Const";
import { DashboardPage } from "@Features/dashboard/presentation/Dashboard.page";
import { AffiliateDashboardPage } from "@Features/affiliate/presentation/AffiliateDashboard.page";
import { RegisterAffiliatePage } from "@Features/affiliate/presentation/RegisterAffiliate.page";
import { AffiliateMainDashboardSection } from "@Features/affiliate/presentation/sections/AffiliateMainDashboard.section";
import { TrackAffiliateUsecase } from "@Features/affiliate/domain/TrackAffiliate.usecase";
import { AffiliateWithdrawDashboardSection } from "@Features/affiliate/presentation/sections/AffiliateWithdrawDashboard.section";
import { AffiliateSettingsSection } from "@Features/affiliate/presentation/sections/AffiliateSettings.section";
import { WatchlistSection } from "@Features/dashboard/presentation/sections/AnalysisSubSection/Watchlist.component";

function MidasApp() {
  const location = useLocation();

  const [cookies, setCookie] = useCookies([KEY_AFFILIATE_ID, KEY_CHECKOUT]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("aff_id") !== null) {
      let type = "open";
      if (location.pathname.includes("add-to-cart")) {
        type = "atc";
      } else if (location.pathname.includes("checkout")) {
        type = "checkout";
      }

      TrackAffiliateUsecase(
        searchParams.get("aff_id") ?? "",
        type,
        window.location.href
      );

      if (
        !cookies.affiliate_id ||
        cookies.is_already_checkout === undefined ||
        cookies.is_already_checkout === true
      ) {
        let expiredDate = new Date();
        expiredDate.setDate(expiredDate.getDate() + AFFILIATE_DAY_DURATION);

        setCookie("affiliate_id", searchParams.get("aff_id"), {
          path: "/",
          expires: expiredDate,
        });
        setCookie("is_already_checkout", false, {
          path: "/",
          expires: expiredDate,
        });
      }
    }

    if (
      searchParams.get("token") !== null &&
      searchParams.get("token") !== ""
    ) {
      let token = searchParams.get("token") ?? "";
      UserSession.loginWithToken(token);
    }

    if (UserSession.checkIsLoggedIn()) {
      GetProfileUsecase.execute()
        .then((response) => {
          UserSession.setUserSession(
            response.id,
            response.fullname,
            response.email,
            UserSession.getToken(),
            response.role,
            response.memberActiveRoles,
            response.pictureUrl,
            response.additionalInfo
          );
        })
        .catch((error) => {
          if (error.message === "Not Authorized") {
            let baseUrl = window.location.origin;
            UserSession.emptySession();
            window.location.href = `${baseUrl}/login?continue=${window.location.href}`;
          }
        });
    }
  }, [location.pathname]);

  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <title>MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan" />
      </Helmet>
      <Routes>
        <Route
          path="add-to-cart"
          element={<CheckoutFormPage state={CheckoutState.ADD_TO_CART} />}
        />
        <Route
          path="checkout"
          element={<CheckoutFormPage state={CheckoutState.CHECKOUT} />}
        />
        <Route
          path="thank-you/:id"
          element={<CheckoutFormPage state={CheckoutState.THANK_YOU} />}
        />
        <Route path="login" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="member-area" element={<DashboardPage />}>
          <Route index element={<StartingGuideSection />} />
          <Route path="philosophy" element={<InvestmentPhilosophySection />} />
          <Route path="courses" element={<CoursesSection />} />
          <Route path="jarvis" element={<JarvisSection />} />
          <Route path="tools" element={<ToolsSection />} />
          <Route path="groups" element={<GroupDiscussionSection />} />
          <Route path="analysis" element={<AnalysisSection />} />
          <Route path="watchlist" element={<WatchlistSection />} />
          <Route path="portfolio" element={<PortfolioSection />} />
          <Route path="records" element={<RecordSection />} />
          <Route path="perpanjang" element={<ExtendSection />} />
          <Route path="upgrade" element={<UpgradeSection />} />
        </Route>
        <Route path="affiliate-register" element={<RegisterAffiliatePage />} />
        <Route path="affiliate" element={<AffiliateDashboardPage />}>
          <Route index element={<AffiliateSettingsSection />} />
          <Route
            path="withdraw"
            element={<AffiliateWithdrawDashboardSection />}
          />
          <Route path="dashboard" element={<AffiliateMainDashboardSection />} />
        </Route>
        <Route path="course/:slug" element={<CourseDetailPage />} />
        <Route path="/" element={<BasePage />}>
          <Route index element={<HomePage />} />
          <Route path="membership" element={<MembershipPage />} />
          <Route path="stocks-key-insights" element={<StockKeyInsightPage />} />
          <Route
            path="investment-philosophy"
            element={<InvestmentPhilosophyPage />}
          />
          <Route path="calculator" element={<CuanCalculatorPage />} />
        </Route>
        <Route path="/:slug" element={<Page />} />
        <Route path="404" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default MidasApp;
