import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { CartModel, CrossSellProductModel } from "../data/cart.model";
import { mapStatusToUi } from "../presentation/utils/OrderStatusMapper";
import { GetPriceUsecase } from "./GetPrice.usecase";

export const GetOrderUsecase: (cartId: string) => Promise<CartModel> = async (
  cartId
) => {
  try {
    let response = await client.get(ApiConst.API_ORDER + "/load/" + cartId);
    let rawData = response.data.data;

    let data: CartModel = {
      ...rawData,
      statusUi: mapStatusToUi(rawData.status),
      items: rawData.items.map((item: any) => {
        let productDescription =
          item.product.description !== null && item.product.description !== ""
            ? JSON.parse(item.description)
            : null;

        let crossSellData: CrossSellProductModel[] | null =
          productDescription !== null && productDescription.crossSells
            ? JSON.parse(productDescription.crossSells)
            : null;

        if (crossSellData !== null) {
          crossSellData = crossSellData.map((cs) => {
            let data = { ...cs };

            GetPriceUsecase(cs.product_id)
              .then((cartProduct) => {
                data.title = cartProduct.title;
                data.price = cartProduct.price;
              })
              .catch((e) => {});

            return data;
          });
        }

        return {
          ...item,
          product: {
            ...item.product,
            crossSell: crossSellData,
          },
        };
      }),
    };

    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
