import { CrossSellProductModel } from "@Features/checkout/data/cart.model";
import { formatToCurrency } from "@Libraries/utils/Formatter";
import { FC } from "react";

export type CrossSellProps = CrossSellProductModel & {
  hasNext: boolean;
  onOtherOfferClicked: () => void;
  onAddOffer: () => void;
  onContinueCheckout: () => void;
};

export const CrossSellModalComponent: FC<CrossSellProps> = ({
  price,
  title,
  hasNext,
  onOtherOfferClicked,
  onAddOffer,
  onContinueCheckout,
}) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-screen w-screen">
      <div className="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white">
        <h3 className="text-lg leading-6 font-bold text-gray-900">
          Limited Offer!
        </h3>
        <div className="mt-2 px-7 py-3">
          <div className="text-base text-gray-500 text-center">
            <div className="font-bold">{title}</div>
            <div>+ {formatToCurrency(price)}</div>
          </div>
        </div>
        <div className="items-center px-4 py-3 flex">
          {hasNext && (
            <button
              type="button"
              className="px-4 py-2 mx-2 bg-transparent text-[#020202] text-base font-medium rounded-md w-full"
              onClick={onOtherOfferClicked}
            >
              Saya Mau Offer Lain
            </button>
          )}
          {!hasNext && (
            <button
              type="button"
              className="px-4 py-2 mx-2 bg-transparent text-[#020202] text-base font-medium rounded-md w-full"
              onClick={onContinueCheckout}
            >
              Tidak, Lanjut Checkout
            </button>
          )}
          <button
            type="button"
            className="px-4 py-2 mx-2 bg-[#ffdd28] text-[#020202] text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2"
            onClick={onAddOffer}
          >
            Saya Mau Offer Ini
          </button>
        </div>
      </div>
    </div>
  );
};
