import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import {
  GENERAL_SELECTED_PRODUCTS,
  GENERAL_TESTIMONI,
  MEMBERSHIP_CAROUSEL,
  MEMBERSHIP_FAQ,
  MEMBERSHIP_FINDING_STOCKS,
  MEMBERSHIP_HEADER_VIDEO,
  MEMBERSHIP_INTRODUCTION_VIDEO,
  MEMBERSHIP_SHOWCASE
} from "@Libraries/utils/Const";

export const FetchMembershipSettingsUsecase = async () => {
  let optionKeys = `${MEMBERSHIP_HEADER_VIDEO};${MEMBERSHIP_FINDING_STOCKS};${MEMBERSHIP_CAROUSEL};${MEMBERSHIP_FAQ};${MEMBERSHIP_INTRODUCTION_VIDEO};${GENERAL_SELECTED_PRODUCTS};${GENERAL_TESTIMONI};${MEMBERSHIP_SHOWCASE}`;
  return await LoadPageSettingUsecase(optionKeys);
};
