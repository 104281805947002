import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AffiliateWithdrawModel } from "../data/affiliate-withdraw.model";

export const GetAffiliateWithdrawUsecase: () => Promise<
  AffiliateWithdrawModel[]
> = async () => {
  try {
    function getStatusClass(status: string) {
      switch (status) {
        case "requested":
          return "color-yellow text-[#7f6e14]";
        case "approved":
          return "color-yellow text-[#7f6e14]";
        case "rejected":
          return "bg-red-500 text-[#772222]";
        case "completed":
          return "bg-green-500 text-[#11622f]";
        default:
          return "";
      }
    }

    function getStatusText(status: string) {
      switch (status) {
        case "requested":
          return "Dalam Pengajuan";
        case "approved":
          return "Disetujui";
        case "rejected":
          return "Ditolak";
        case "completed":
          return "Selesai";
        default:
          return "";
      }
    }

    let response = await client.get(ApiConst.API_AFFILIATES + "/withdraw");
    let data = response.data.data;

    let returnData = data.map((item: any) => ({
      affiliateName: item.affiliate_name,
      amount: item.amount,
      bankTarget: item.bank_target,
      bank: {
        affiliateName: item.bank.affiliate_name,
        bankAccountNumber: item.bank.bank_account_number,
        bankHolderName: item.bank.bank_holder_name,
        bankName: item.bank.bank_name,
        createdAt: item.bank.created_at,
        id: item.bank.id,
        updatedAt: item.bank.updated_at,
        userId: item.bank.user_id,
      },
      createdAt: item.created_at,
      id: item.id,
      notes: item.notes,
      status: getStatusText(item.status),
      statusClass: getStatusClass(item.status),
      updatedAt: item.updated_at,
    }));

    return returnData;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
