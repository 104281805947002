import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { MidasTestimoniComponent } from "@Features/common/presentation/components/MidasTestimoni.component";
import { MidasStockMembershipPriceComponent } from "@Libraries/components/prices/MidasStockMembershipPrice.component";
import {
  GENERAL_SELECTED_PRODUCTS,
  GENERAL_TESTIMONI,
  HOMEPAGE_PORTFOLIO,
  HOMEPAGE_SHOWCASE,
} from "@Libraries/utils/Const";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchHomepageSettingsUsecase } from "../domain/FetchHomepageSettings.usecase";
import { MembershipSection } from "./components/MembershipSection.component";
import { PortfolioSection } from "./components/PortfolioSection.component";
import { ShowcaseThumbnailSection } from "./components/ShowcaseThumbnailSection.component";
import { TopHeaderSection } from "./components/TopHeaderSection.component";
import { default as BukanStockPick } from "@Assets/midas-bukan-stock-pick.webp";
import { MediaSection } from "./components/MediaSection.component";

export const HomePage: FC = () => {
  const navigate = useNavigate();
  const [pageSetting, setPageSetting] = useState<PageSettingListModel>({});

  const priceRef = useRef<null | HTMLElement>(null);

  useEffect(() => {
    FetchHomepageSettingsUsecase()
      .then((response) => {
        setPageSetting(response);
      })
      .catch((errors) => {});
  }, []);

  const onStartNowClicked = () => {
    navigate({
      hash: "start-now",
    });
    priceRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <section>
        <TopHeaderSection onClick={onStartNowClicked} />
      </section>
      <MediaSection />
      {pageSetting[HOMEPAGE_PORTFOLIO] && (
        <section>
          <PortfolioSection
            data={JSON.parse(pageSetting[HOMEPAGE_PORTFOLIO])}
          />
        </section>
      )}
      <section>
        <MembershipSection />
      </section>
      {pageSetting[HOMEPAGE_SHOWCASE] && (
        <section>
          <ShowcaseThumbnailSection
            data={JSON.parse(pageSetting[HOMEPAGE_SHOWCASE])}
            onClick={onStartNowClicked}
          />
        </section>
      )}
      {pageSetting[GENERAL_SELECTED_PRODUCTS] && (
        <section id="start-now" ref={priceRef}>
          <MidasStockMembershipPriceComponent
            data={JSON.parse(pageSetting[GENERAL_SELECTED_PRODUCTS])}
          />
        </section>
      )}
      {pageSetting[GENERAL_TESTIMONI] && (
        <section>
          <MidasTestimoniComponent
            carouselId="homepage-testimoni"
            data={JSON.parse(pageSetting[GENERAL_TESTIMONI])}
          />
        </section>
      )}
      <div>
        <img
          src={BukanStockPick}
          alt="MIDAS CUAN - Bukan Stock Pick"
          className="mx-auto w-full sm:w-1/2"
        />
      </div>
    </>
  );
};
