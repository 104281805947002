import { default as Image } from "@Assets/philosphy/benjamin-graham.png";

export const QuoteSection = () => {
  return (
    <div className="mx-7 md:mx-28 mt-4 md:mt-28 grid grid-cols-1 md:grid-cols-2">
      <div className="md:p-14 my-auto mx-auto">
        <img
          src={Image}
          alt="MidasCuan - Investment Philosphy"
          className="w-full object-cover max-h-[390px] max-w-[388px]"
        />
      </div>
      <div className="rounded-3xl bg-portfolio w-full p-14 mt-4">
        <div className="color-midas-neutral-900 font-bold text-xl md:text-2xl lg:text-4xl">
          “Dalam jangka pendek, pasar saham adalah mesin voting. Tapi dalam
          jangka panjang, pasar saham adalah mesin timbangan.”
        </div>
        <div className="color-midas-neutral-900 font-normal text-lg md:text-xl lg:text-3xl mt-6">
          | Benjamin Graham
        </div>
      </div>
    </div>
  );
};
