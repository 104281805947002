/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { HeaderLogoOnly } from "@Libraries/components/headers/HeaderLogoOnly.component";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { useEffect, useState } from "react";
import { FC } from "react";
import {
  Control,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CartModel, CrossSellProductModel } from "../data/cart.model";
import { CheckoutUsecase } from "../domain/Checkout.usecase";
import { CreateOrderUsecase } from "../domain/CreateOrder.usecase";
import { DeleteCartId } from "../domain/DeleteCartId.usecase";
import { GetCartId } from "../domain/GetCartId.usecase";
import { GetOrderUsecase } from "../domain/GetOrder.usecase";
import { CheckoutLeftForm } from "./components/CheckoutLeftForm.component";
import { CheckoutPaymentForm } from "./components/CheckoutPaymentForm.component";
import { CheckoutRightForm } from "./components/CheckoutRightForm.component";
import { CheckoutUserDetailForm } from "./components/CheckoutUserDetailForm.component";
import { Helmet } from "react-helmet";
import { CrossSellModalComponent } from "./components/CrossSellModal.component";
import { useCookies } from "react-cookie";
import {
  AFFILIATE_DISCOUNT_AMOUNT,
  KEY_AFFILIATE_ID,
  KEY_CHECKOUT,
} from "@Libraries/utils/Const";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import { isAxiosError } from "axios";

export enum CheckoutState {
  ADD_TO_CART,
  CHECKOUT,
  THANK_YOU,
}

export type CheckoutFormProps = {
  register: UseFormRegister<CheckoutInput>;
  watch: UseFormWatch<CheckoutInput>;
  setValue: UseFormSetValue<CheckoutInput>;
  control: Control<CheckoutInput, any>;
};

export type AdditionalInfo = {
  label: string;
  value: string;
};

export type CheckoutInput = {
  fullname?: string;
  password?: string;
  email?: string;
  phone?: string;
  telegram?: string;
  paymentMethod?: string;
  additionalInfo?: AdditionalInfo[];
};

export type CheckoutProps = {
  state: CheckoutState;
};

export const CheckoutFormPage: FC<CheckoutProps> = ({ state }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [cookies, setCookie] = useCookies([KEY_AFFILIATE_ID, KEY_CHECKOUT]);

  const [needRefresh, setNeedRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountExists, setIsAccountExists] = useState(false);
  const [cartId, setCartId] = useState("");
  const [cartData, setCartData] = useState<CartModel | null>(null);
  const [listCrossSell, setListCrossSell] = useState<CrossSellProductModel[]>(
    []
  );
  const [crossSellIndex, setCrossSellIndex] = useState(-1);
  const [showCrossSell, setShowCrossSell] = useState(false);
  const [affiliateDiscount, setAffiliateDiscount] = useState(0);

  const toasterContext = UseToasterContext();

  const { register, handleSubmit, watch, setValue, control } =
    useForm<CheckoutInput>();

  useEffect(() => {
    if (params.id) {
      setCartId(params.id);
    } else {
      setCartId(GetCartId());
    }

    if (
      searchParams.get("product") &&
      searchParams.get("product") !== "" &&
      state === CheckoutState.ADD_TO_CART
    ) {
      let productList = [
        {
          product_id: searchParams.get("product"),
          qty: 1,
        },
      ];

      let formData = new FormData();
      formData.append("products", JSON.stringify(productList));
      if (cartId !== "") {
        formData.append("cart_id", cartId);
      }

      CreateOrderUsecase(formData)
        .then((response: CartModel) => {
          let currentCrossSell = [...listCrossSell];

          response.items.forEach((item) => {
            item.product.crossSell?.forEach((cs) => {
              let checkCurrent = currentCrossSell.filter(
                (product) => product.product_id === cs.product_id
              );
              if (!checkCurrent || checkCurrent.length === 0) {
                currentCrossSell.push(cs);
              }
            });
          });

          setListCrossSell(currentCrossSell);
          setCartData(response);
          navigate("/checkout");
        })
        .catch((error) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to Create Order",
              error.message,
              ToasterType.DANGER
            ),
          ]);
        });
    } else {
      fetchCartDetail();
    }

    if (state === CheckoutState.CHECKOUT && cartId === "") {
      navigate("/");
    } else if (state === CheckoutState.CHECKOUT) {
      getAffiliateDiscount();
    }
    getAffiliateDiscount();
  }, [cartId]);

  useEffect(() => {
    if (cartId === "") {
      if (params.id) {
        setCartId(params.id);
      } else {
        setCartId(GetCartId());
      }
    }

    if (state === CheckoutState.THANK_YOU) {
      const interval = setInterval(() => {
        fetchCartDetail();

        if (
          cartData &&
          (cartData.status === "paid" || cartData.status === "complete")
        ) {
          navigate("/member-area");
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [state]);

  useEffect(() => {
    if (needRefresh) {
      fetchCartDetail();
      setNeedRefresh(false);
    }
  }, [needRefresh]);

  const getAffiliateDiscount = async () => {
    try {
      let settings = await LoadPageSettingUsecase(AFFILIATE_DISCOUNT_AMOUNT);
      setAffiliateDiscount(parseInt(settings[AFFILIATE_DISCOUNT_AMOUNT]));
    } catch (e) {
      // do nothing
      setAffiliateDiscount(0);
    }
  };

  const addProductToCart = async (
    productId: string,
    continueCheckout: boolean
  ) => {
    try {
      let productList = [
        {
          product_id: productId,
          qty: 1,
        },
      ];

      let formData = new FormData();
      formData.append("products", JSON.stringify(productList));
      if (cartId !== "") {
        formData.append("cart_id", cartId);
      }

      let result = await CreateOrderUsecase(formData);
      setCartData(result);

      if (continueCheckout) handleSubmit(onSubmit)();
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Gagal menambahkan product", "", ToasterType.DANGER),
      ]);
    }
  };

  const fetchCartDetail = async () => {
    if (cartId && cartId !== "") {
      try {
        let cartDetail = await GetOrderUsecase(cartId);
        if (cartDetail.status === "paid" || cartDetail.status === "complete") {
          navigate("/member-area");
        }

        let currentCrossSell = [...listCrossSell];
        cartDetail.items.forEach((item) => {
          item.product.crossSell?.forEach((cs) => {
            let checkCurrent = currentCrossSell.filter(
              (product) => product.product_id === cs.product_id
            );
            if (!checkCurrent || checkCurrent.length === 0) {
              currentCrossSell.push(cs);
            }
          });
        });

        setListCrossSell(currentCrossSell);
        setCartData(cartDetail);
      } catch (error) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to Fetch Order",
            error.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
  };

  const onSubmit: (data: CheckoutInput) => void = async (data) => {
    if (listCrossSell.length > crossSellIndex + 1) {
      setCrossSellIndex(crossSellIndex + 1);
      setShowCrossSell(true);
      return;
    }

    setShowCrossSell(false);
    setIsLoading(true);

    try {

      let checkoutUrl = await CheckoutUsecase(
        cartId,
        data.email ?? "",
        data.password ?? "",
        data.fullname ?? "",
        data.phone ?? "",
        data.telegram ?? "",
        data.additionalInfo ?? [],
        cartData?.items[0],
        cookies.affiliate_id
      );

      let selectedPayment = "";
      if (data.paymentMethod) {
        selectedPayment = `#${data.paymentMethod}`;
      }

      await DeleteCartId();

      if (cookies.affiliate_id) {
        setCookie("is_already_checkout", true);
      }
      window.open(`${checkoutUrl}${selectedPayment}`, "_self");
      setIsAccountExists(false);
    } catch (error) {
      if(isAxiosError(error)) {
        setIsAccountExists(true);
      } else {
        setIsAccountExists(false);
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to Checkout",
            error.message,
            ToasterType.DANGER
          ),
        ]);  
      }
    } finally {
      setIsLoading(false);
    }
  };

  const buildCheckoutForm = () => {
    if (cartData === null) return <></>;

    return (
      <>
        {/* right content */}
        <div className="mr-4 ml-4 w-full lg:text-left lg:ml-10 lg:mr-28 md:order-3">
          <CheckoutRightForm
            cartId={cartData.id}
            cartItems={cartData.items}
            subPrice={cartData.real_total_price}
            subPriceStr={cartData.real_total_price_str}
            totalPrice={cartData.total_price}
            totalPriceStr={cartData.total_price_str}
            discountPrice={cartData.real_total_price - cartData.total_price}
            affiliateDiscount={affiliateDiscount}
            affiliateId={cookies.affiliate_id}
            voucher={cartData.voucher}
            setNeedRefresh={setNeedRefresh}
          />
        </div>

        {/* divider */}
        <div className="border-l h-auto hidden md:block md:order-2" />

        {/* Left content */}
        <div className="mr-4 ml-4 w-full flex flex-col lg:mr-10 lg:ml-28 md:order-1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <CheckoutLeftForm
              nextButton={
                <button
                  type={"submit"}
                  className="color-yellow rounded-lg font-bold text-sm py-4 px-6 uppercase"
                  style={{
                    backgroundColor: "#ffdd28",
                  }}
                >
                  {"Buat Pesanan"}
                </button>
              }
              bodyContent={
                <>
                  <CheckoutUserDetailForm
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    control={control}
                    isAccountExists={isAccountExists}
                  />

                  <CheckoutPaymentForm
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    register={register}
                  />
                </>
              }
            />
          </form>
        </div>
      </>
    );
  };

  const buildThankYouView = () => {
    return (
      <div className="w-full flex justify-center">
        <div className="w-1/2">
          <h1 className="font-bold text-2xl text-center">Terima kasih!</h1>
          <h3 className="text-lg text-center">
            Pesanan Anda dengan nomor invoice{" "}
            <span className="text-xl font-bold">#{cartId}</span> dalam proses
          </h3>
          {cartData && (
            <div className="mt-10">
              <MidasShimmering />
              <p className="flex flex-col md:flex-row justify-center mt-10">
                <span className="my-auto text-center md:text-left">Status</span>
                <span className="md:ml-4 font-semibold text-lg text-gray-500 text-center md:text-left">
                  {cartData.statusUi}
                </span>
              </p>
              <div className="flex flex-col md:flex-row justify-center mt-10">
                <button className="button-yellow">
                  <a
                    href={cartData.checkout_url ?? "#"}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    Lihat Panduan Pembayaran
                  </a>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white">
      <Helmet encodeSpecialCharacters={true}>
        <title>Checkout - MIDAS Cuan</title>
        <meta name="description" content="Checkout - MIDAS Cuan" />
      </Helmet>
      <HeaderLogoOnly
        leftComponent={
          <Link className="flex my-auto text-white h-full" to={"/"}>
            <div className="w-6 my-auto">
              <FaChevronLeft />
            </div>
            <div className="ml-2 text-sm font-normal my-auto hidden md:block">
              Back to homepage
            </div>
          </Link>
        }
      />

      <div className="mt-9 mr-8 mx-auto flex flex-col md:flex-row lg:mt-16 lg:justify-center lg:mr-0">
        {cartData === null || isLoading ? (
          <MidasShimmering />
        ) : state !== CheckoutState.THANK_YOU ? (
          buildCheckoutForm()
        ) : (
          buildThankYouView()
        )}
      </div>

      {/* Affiliate Info */}
      {cookies.affiliate_id && !isLoading && (
        <div className="w-full my-6 text-center opacity-50">
          {cookies.affiliate_id}
        </div>
      )}

      {showCrossSell && crossSellIndex >= 0 && (
        <CrossSellModalComponent
          price={listCrossSell[crossSellIndex].price}
          product_id={listCrossSell[crossSellIndex].product_id}
          title={listCrossSell[crossSellIndex].title}
          hasNext={listCrossSell.length > crossSellIndex + 1}
          onAddOffer={() =>
            addProductToCart(listCrossSell[crossSellIndex].product_id, true)
          }
          onContinueCheckout={() => {
            handleSubmit(onSubmit)();
          }}
          onOtherOfferClicked={() => setCrossSellIndex(crossSellIndex + 1)}
        />
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
