import { FC } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { CarouselListModel } from "./models/carousel-list.model";
import { CarouselModel } from "./models/carousel.model";
import { MidasVideoPlayer } from "../video-player/VideoPlayer.component";

export type ShowcaseCarouselProps = CarouselListModel & {
  onClick?: (model: CarouselModel, index: number) => void;
  onNext: () => void;
  onPrev: () => void;
};

export const ShowcaseCarouselComponent: FC<ShowcaseCarouselProps> = ({
  carouselId,
  carouselList,
  focusedIndex,
  onClick,
  onNext,
  onPrev,
}) => {
  const getTranslatePosition: (index: number) => string = (index) => {
    let currentPosition = index - focusedIndex;
    currentPosition++;

    if (currentPosition === 1 && window.innerWidth > 768) {
      return "28%";
    } else if (currentPosition === 1) {
      return "0%";
    }

    if (currentPosition === 2) {
      return "45%";
    }

    if (currentPosition < 0) {
      currentPosition = index + carouselList.length - focusedIndex + 1;
      if (currentPosition < 0) {
        currentPosition *= -1;
      }
      return (currentPosition * 25 - 5).toString() + "%";
    }

    if (currentPosition >= carouselList.length) {
      currentPosition -= carouselList.length;
      return (currentPosition * 25 - 5).toString() + "%";
    }

    return (currentPosition * 25 - 5).toString() + "%";
  };

  const getScaleValue: (index: number) => string = (index) => {
    if (index === focusedIndex) return "1";

    return "0.8";
  };

  const getPlayerWidthPercentage: () => number = () => {
    if (window.innerWidth >= 768) {
      return 50;
    } else {
      return 100;
    }
  };

  return (
    <div className="grid place-items-center" id={carouselId}>
      <div className="w-full showcase-container-small md:showcase-container relative overflow-hidden">
        <button
          className="color-yellow rounded-full w-fit h-fit p-3 absolute left-0 top-[43%] z-30"
          onClick={onPrev}
        >
          <FaArrowLeft size={18} />
        </button>
        {carouselList.map((item, index) => (
          <div
            key={item.key}
            className={`w-full showcase-container-small md:showcase-container absolute transition-all duration-500 ${
              index === focusedIndex ? "z-20" : "z-10"
            }`}
            style={{
              transform: `translateX(${getTranslatePosition(
                index
              )}) scale(${getScaleValue(index)})`,
            }}
            onClick={() => onClick && onClick(item, index)}
          >
            <MidasVideoPlayer
              componentKey={`MidasCuan - ${item.altText}`}
              videoUrl={item.imageUrl}
              options={{
                widthPercentage: getPlayerWidthPercentage(),
                showVideoControls: true,
                isAutoPlay: false,
                canDownload: false,
                videoSource: "url",
                isCenter: false,
              }}
            />
          </div>
        ))}
        <button
          className="color-yellow rounded-full w-fit h-fit p-3 absolute right-0 top-[43%] z-30"
          onClick={onNext}
        >
          <FaArrowRight size={18} />
        </button>
      </div>
    </div>
  );
};
