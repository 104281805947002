export const RiskSection = () => {
  return (
    // <div className="w-full mt-10 md:mt-20 grid grid-cols-3">
    <div className="my-20 mx-7 md:mx-28 md:mt-13 text-left">
      <div className="col-span-3 md:col-span-2 mx-7 md:mx-28">
        <div>
          <div className="font-extrabold text-white responsive-header-title text-center md:text-left">
            Apa yang kita{" "}
            <span className="color-brand-yellow inline-block md:block">
              maksud dengan Risiko?
            </span>
          </div>
          <div className="text-white font-normal text-xl mt-6">
            Risiko menurut kami adalah kemungkinan buruk yang mungkin terjadi
            pada bisnis di perusahaan yang kita beli sahamnya. Kita menganalisa
            apakah pesaingnya bisa merebut market sharenya, apakah bisnis model
            masih relevan/sudah ditinggalkan? Semua ini tidak ada hubungannya
            dengan VOLATILITAS HARGA.
          </div>
          <div className="text-white font-normal text-xl mt-6">
            Saham perusahaan yang bagus mungkin saja harganya naik dan turun
            dalam jangka pendek, tapi jika fokus kita adalah jangka panjang,
            volatilitas harga jangka pendek sudah menjadi tidak relevan lagi.
          </div>
        </div>
      </div>
    </div>
  );
};
