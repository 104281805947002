import secureLocalStorage from "react-secure-storage";

export const CART_KEY = "CART_KEY";

export const SaveCartId: (cartId: string) => Promise<boolean> = async (
  cartId
) => {
  if (typeof Storage !== "undefined") {
    secureLocalStorage.setItem(CART_KEY, cartId);
    return true;
  }

  return false;
};
