import {
  CartItemModel,
  CartProductModel,
  CartVoucherModel,
} from "@Features/checkout/data/cart.model";
import { ApplyVoucherUsecase } from "@Features/checkout/domain/ApplyVoucher.usecase";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { formatToCurrency } from "@Libraries/utils/Formatter";
import { FC, useRef, useState } from "react";
import { PriceDetail } from "./PriceDetail.component";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { RemoveVoucherUsecase } from "@Features/checkout/domain/RemoveVoucher.usecase";
import { RemoveProductUsecase } from "@Features/checkout/domain/RemoveProduct.usecase";
import { BiLoader } from "react-icons/bi";
import { Toaster } from "@Libraries/components/toast/Toast.component";

export type CheckoutRightFormProps = {
  cartId: number;
  cartItems: CartItemModel[];
  totalPrice: number;
  totalPriceStr: string;
  discountPrice: number;
  subPrice: number;
  subPriceStr: string;
  voucher?: CartVoucherModel;
  affiliateDiscount: number;
  affiliateId: string;
  setNeedRefresh: (needRefresh: boolean) => void;
};

export type CheckoutProductItemProps = CartProductModel & {
  numberOfItems: number;
  onDeleteProduct: (productId: string) => void;
};

export const CheckoutProductItemComponent: FC<CheckoutProductItemProps> = (
  product
) => {
  return (
    <div className="mt-6 my-auto flex">
      {product.numberOfItems > 1 && (
        <button
          className="my-auto mr-4"
          onClick={() => product.onDeleteProduct(product.id.toString())}
        >
          <AiOutlineCloseCircle color="red" size={16} />
        </button>
      )}
      <div>
        <div className="font-bold text-lg">{product.title}</div>
        <div className="color-2d2d2d opacity-50 text-sm">
          {product.duration_length}{" "}
          {product.duration_type === "day"
            ? "Hari"
            : product.duration_type === "month"
              ? "Bulan"
              : "Tahun"}
        </div>
      </div>
    </div>
  );
};

export const CheckoutRightForm: FC<CheckoutRightFormProps> = ({
  cartId,
  discountPrice,
  cartItems,
  subPrice,
  subPriceStr,
  totalPrice,
  totalPriceStr,
  voucher,
  affiliateDiscount,
  affiliateId,
  setNeedRefresh,
}) => {
  const toasterContext = UseToasterContext();

  const [voucherLoading, setVoucherLoading] = useState(false);

  const voucherRef = useRef<HTMLInputElement | null>(null);

  const applyVoucher = () => {
    if (!voucherLoading) {
      let voucherCode = voucherRef.current?.value;
      setVoucherLoading(true);

      if (
        voucherCode !== undefined &&
        voucherCode !== null &&
        voucherCode !== ""
      ) {
        ApplyVoucherUsecase(voucherCode, cartId.toString())
          .then((response) => {
            setNeedRefresh(true);
            toasterContext.setToastList([
              ...toasterContext.toastList,
              new ToasterModel(
                "Voucher berhasil di gunakan!",
                "",
                ToasterType.SUCCESS
              ),
            ]);
          })
          .catch((errors) => {
            toasterContext.setToastList([
              ...toasterContext.toastList,
              new ToasterModel(
                "Failed to apply voucher!",
                errors.message,
                ToasterType.DANGER
              ),
            ]);
          })
          .finally(() => {
            setVoucherLoading(false);
          });
      }
    }
  };

  const removeVoucher = () => {
    RemoveVoucherUsecase(cartId.toString())
      .then((response) => {
        setNeedRefresh(true);
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Voucher berhasil di hapus!",
            "",
            ToasterType.SUCCESS
          ),
        ]);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to remove voucher!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const removeProduct = async (productId: string) => {
    try {
      await RemoveProductUsecase(cartId.toString(), productId);
      setNeedRefresh(true);
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Product berhasil di hapus!", "", ToasterType.SUCCESS),
      ]);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to remove product!",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const buildApplyVoucherView = () => {
    return (
      <>
        <input
          ref={voucherRef}
          type="text"
          id="checkout-discount-code"
          className="form-input uppercase w-full"
          placeholder="Discount code"
        />
        <button
          className="ml-4 p-4 bg-2d2d2d rounded-md text-sm text-white font-bold"
          onClick={applyVoucher}
        >
          {voucherLoading && <BiLoader size={16} className="animate-spin" />}
          {!voucherLoading && "Terapkan"}
        </button>
      </>
    );
  };

  const buildVoucherView = () => {
    return (
      <div className="border rounded shadow p-4 w-full flex">
        <div className="w-full">
          <div className="font-bold text-lg uppercase">
            {affiliateId === undefined
              ? voucher?.code
              : "REFERRAL"
            }
          </div>
        </div>
        {voucher !== null && affiliateId === undefined && (
          <button className="text-red-500" onClick={removeVoucher}>
            <AiOutlineCloseCircle size={24} />
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="text-form-title">Ringkasan Pesanan</div>
      <h5>
        Jika jumlah bayar tidak sesuai (lebih mahal) klik (x) pada produk yang
        tidak diinginkan agar jumlah sesuai
      </h5>
      {cartItems.map((item, index) => {
        return (
          <CheckoutProductItemComponent
            key={`product-${index}-${item.id}`}
            {...item.product}
            numberOfItems={cartItems.length}
            onDeleteProduct={removeProduct}
          />
        );
      })}
      <div className="flex mt-11">
        {voucher === null
          && affiliateId === undefined
          ? buildApplyVoucherView()
          : buildVoucherView()}
      </div>
      <div className="my-6 w-full h-px border-t"></div>
      <PriceDetail
        label="Subtotal"
        price={subPriceStr}
        isBold={false}
        isBig={false}
        customClass={"mt-6"}
      />
      <PriceDetail
        label="Diskon Promo"
        price={formatToCurrency(discountPrice)}
        isBold={false}
        isBig={false}
        customClass={"mt-6"}
      />

      {affiliateId !== undefined && affiliateDiscount > 0 && (
        <PriceDetail
          label="Diskon Referral"
          price={formatToCurrency(affiliateDiscount)}
          isBold={false}
          isBig={false}
          customClass="mt-6"
        />
      )}

      <PriceDetail
        label="Total"
        price={
          affiliateId !== undefined
            ? formatToCurrency(totalPrice - affiliateDiscount)
            : formatToCurrency(totalPrice)
        }
        isBold={true}
        isBig={true}
        customClass={"mt-6 mb-6"}
      />

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </>
  );
};
