import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const RequestAffiliateWithdrawUseCase: (
  amount: number,
  bank: number
) => Promise<string> = async (amount, bank) => {
  try {
    let formData = new FormData();

    formData.append("amount", amount.toString());
    formData.append("bank", bank.toString());

    let response = await client.post(
      ApiConst.API_AFFILIATES + "/withdraw",
      formData
    );
    if (response.status === 200) {
      return response.data.message;
    }
    return "";
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
