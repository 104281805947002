import { FC } from "react";

export type PortfolioItemModel = {
  year: string;
  imageUrl: string;
};

export type PortfolioProps = {
  data: PortfolioItemModel[];
};

export const PortfolioSection: FC<PortfolioProps> = ({ data }) => {
  return (
    <div className="portfolio-container">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="font-extrabold responsive-header-title">
          Portfolio <div className="color-brand-yellow">MIDAS vs IHSG</div>
        </div>
      </div>
      <div className="mt-10">
        {data.length > 0 && (
          <img
            src={data[0].imageUrl}
            alt="MidasCuan : Portfolio MIDAS vs IHSG"
          />
        )}
      </div>
    </div>
  );
};
