import {
  LoadPageSettingUsecase,
  PageSettingListModel,
} from "@Features/common/domain/LoadPageSettings.usecase";
import { LINK_ZOOM_WM } from "@Libraries/utils/Const";

export const GetLinkZoomUsecase: () => Promise<PageSettingListModel> =
  async () => {
    let optionKeys = `${LINK_ZOOM_WM}`;
    return await LoadPageSettingUsecase(optionKeys);
  };
