import { ClickListenerType } from "@Features/common/presentation/listener/Click.listener";
import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import {
  CarouselListModel,
  CarouselType,
} from "@Libraries/components/carousels/models/carousel-list.model";
import { ShowcaseCarouselComponent } from "@Libraries/components/carousels/ShowcaseCarousel.component";
import { FC, useState } from "react";

export type ShowcaseItemModel = {
  imageUrl: string;
  altText: string;
};

export type ShowcaseProps = ClickListenerType & {
  data: ShowcaseItemModel[];
};

export const ShowcaseMembershipThumbnailSection: FC<ShowcaseProps> = ({
  onClick,
  data,
}) => {
  const onNext = () => {
    let newCarouselState = { ...carouselState };
    newCarouselState.focusedIndex = ++carouselState.focusedIndex;

    if (newCarouselState.focusedIndex >= newCarouselState.carouselList.length) {
      newCarouselState.focusedIndex = 0;
      carouselState.focusedIndex = 0;
    }

    setCarouselState(newCarouselState);
  };

  const onPrev = () => {
    let newCarouselState = { ...carouselState };
    newCarouselState.focusedIndex = --carouselState.focusedIndex;

    if (newCarouselState.focusedIndex < 0) {
      newCarouselState.focusedIndex = newCarouselState.carouselList.length - 1;
      carouselState.focusedIndex = carouselState.carouselList.length - 1;
    }

    setCarouselState(newCarouselState);
  };

  const [carouselState, setCarouselState] = useState<CarouselListModel>({
    carouselId: "membershipShowcaseCarousel",
    type: CarouselType.SHOWCASE,
    focusedIndex: 0,
    carouselList: data.map((item, index) => {
      return {
        key: `showcase-${index}`,
        imageUrl: item.imageUrl,
        altText: item.altText,
      };
    }),
  });

  return (
    <div className="mt-24 mx-7 md:mx-16">
      <div className="font-extrabold text-center color-brand-yellow responsive-header-title">
        Video Cuplikan Weekly Meeting
      </div>
      <div className="mt-7 mb-20 md:mt-14 font-normal text-xl text-white">
        <ShowcaseCarouselComponent
          {...carouselState}
          onNext={onNext}
          onPrev={onPrev}
        />
      </div>
    </div>
  );
};
