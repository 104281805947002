/* eslint-disable react-hooks/exhaustive-deps */
import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import { UserSession } from "@Libraries/users/UserSession";
import { CHECKOUT_ADDITIONAL_INFO } from "@Libraries/utils/Const";
import { FC, useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { FaEye, FaEyeSlash, FaUserAlt } from "react-icons/fa";
import { CheckoutFormProps } from "../CheckoutForm.page";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { ToasterModel, ToasterType } from "@Libraries/components/toast/Toast.model";

export type CheckoutUserDetailProps = CheckoutFormProps & {
  isAccountExists: boolean;
};

export type AdditionalInfoModel = {
  label: string;
  options: string[];
};

export const CheckoutUserDetailForm: FC<CheckoutUserDetailProps> = ({
  register,
  watch,
  setValue,
  control,
  isAccountExists,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMess, setShowErrorMess] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfoModel[]>(
    []
  );
  const [isLoginState, setIsLoginState] = useState(false);
  const [isProcessingLogin, setIsProcessingLogin] = useState(false);

  const { fields, append } = useFieldArray({
    control,
    name: "additionalInfo",
  });

  const toasterContext = UseToasterContext();

  useEffect(() => {
    LoadPageSettingUsecase(CHECKOUT_ADDITIONAL_INFO)
      .then((response) => {
        let setting: AdditionalInfoModel[] = (
          JSON.parse(response[CHECKOUT_ADDITIONAL_INFO]) as any[]
        ).map((item) => {
          return {
            label: item.label,
            options:
              item.option !== "" && item.option.includes("[")
                ? JSON.parse(item.option)
                : [],
          };
        });
        setAdditionalInfo(setting);
      })
      .catch((errors) => {
        //do nothing
      });
  }, []);

  useEffect(() => {
    if (
      additionalInfo.length !== 0 &&
      fields.length !== additionalInfo.length
    ) {
      for (let i = 0; i < additionalInfo.length - fields.length; i++) {
        append({
          label: additionalInfo[i].label,
          value: "",
        });
      }
    }
  }, [additionalInfo]);

  const login = async () => {
    if (!watch("email") || !watch("password")) {
      return;
    }

    setIsProcessingLogin(true);
    setShowErrorMess(null)

    try {
      await UserSession.login(watch("email") ?? "", watch("password") ?? "", true);
    } catch (error) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          error.message,
          "",
          ToasterType.DANGER
        ),
      ]);
      setShowErrorMess(error.message)
    } finally {
      setIsProcessingLogin(false);
    }
  }

  const buildLoggedInView = () => {
    return (
      <div className="border rounded shadow grid grid-cols-6 p-6 mt-6">
        <div className="font-normal text-sm">
          {
            <div className="rounded-full bg-gray-500 w-12 h-12 m-auto">
              {UserSession.getPicture() ? (
                <img
                  src={UserSession.getPicture()}
                  alt={`Profile ${UserSession.getName()}`}
                  className="object-cover"
                />
              ) : (
                <div className="pt-2">
                  <FaUserAlt size={28} className="m-auto" />
                </div>
              )}
            </div>
          }
        </div>
        <div className="ml-6 w-full col-span-5 my-auto">
          <div className="text-sm font-bold">{UserSession.getName()}</div>
          <div className="text-sm font-normal color-333333">
            {UserSession.getEmail()}
          </div>
        </div>
      </div>
    );
  };

  const buildNotLoggedInView = () => {
    return (
      <>
        {isAccountExists && <div className="border text-red-500 p-4 rounded-xl">
          <div className="m-auto font-bold">
            Email yang Anda masukkan sudah terdaftar. Silahkan login atau ubah email.
          </div>
          <button type="button" className={`${isLoginState ? "button-gray" : "button-yellow"} mt-2`} onClick={() => setIsLoginState(!isLoginState)}>
            {isLoginState ? "Ubah Email" : "Login"}
          </button>
        </div>}

        {isLoginState && buildNotLoggedInLoginForm()}
        {!isLoginState && buildNotLoggedInForm()}
      </>
    );
  };

  const buildNotLoggedInForm = () => {
    return <>
      <div className="text-form-label mt-6">Name</div>
      <input
        type="text"
        className="form-input mt-3"
        placeholder="Enter your name"
        {...register("fullname", { required: "Name is required" })}
      />
      <div className="text-form-label mt-6">Password</div>
      <div className="flex form-input">
        <input
          type={showPassword ? "text" : "password"}
          className="w-full outline-none"
          placeholder="Enter your password"
          {...register("password", {
            required: "Password is required.",
          })}
        />
        <div
          className="w-6 h-6 color-brand-yellow"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        <div>
          <div className="text-form-label">E-mail</div>
          <input
            type="text"
            id="checkout-email"
            className="form-input mt-3"
            placeholder="Enter your e-mail"
            {...register("email", { required: "Email is required" })}
          />
        </div>
        <div>
          <div className="text-form-label">Nomor Telepon</div>
          <div className="form-input mt-3 flex">
            <div className="font-bold text-sm">+62</div>
            <input
              type="text"
              id="checkout-phone"
              className="focus:outline-none ml-2 w-full"
              placeholder="Cth: 81213141516"
              {...register("phone")}
            />
          </div>
        </div>
        <div>
          <div className="text-form-label">Username Telegram</div>
          <input
            type="text"
            id="checkout-telegram"
            className="form-input mt-3"
            placeholder="Enter your username"
            {...register("telegram")}
          />
          <div className="text-xs mt-2">
            Buka Telegram Anda &gt; Klik Menu ⋮ &gt; Pilih Settings &gt; Set
            usernamemu (di bawah no. telp)
          </div>
        </div>

        {fields.map((item, index) => {
          if (additionalInfo[index]) {
            return (
              <div key={item.id}>
                <div className="text-form-label">
                  {additionalInfo[index].label}
                </div>
                {additionalInfo[index].options.length > 0 ? (
                  <select
                    className="form-input mt-3"
                    {...register(`additionalInfo.${index}.value`)}
                  >
                    <option value=""></option>
                    {additionalInfo[index].options.map(
                      (option, optionIndex) => {
                        return (
                          <option
                            key={`${additionalInfo[index].label}-${optionIndex}`}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                    )}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-input mt-3"
                    placeholder={`Enter ${additionalInfo[index].label}`}
                    {...register(`additionalInfo.${index}.value`)}
                  />
                )}
              </div>
            );
          } else {
            return <div key={item.id}></div>;
          }
        })}
      </div>
    </>;
  }

  const buildNotLoggedInLoginForm = () => {
    if (isProcessingLogin) {
      return <MidasShimmering />;
    } else {
      return <div>
          <div className="mt-4 text-center font-bold text-red-400">{(showErrorMess !== null && showErrorMess)}</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-4">
          <div className="text-form-label my-auto">E-mail</div>
          <input
            type="text"
            id="checkout-email"
            className="form-input w-full md:col-span-2"
            placeholder="Enter your e-mail"
            {...register("email", { required: "Email is required" })}
          />

          <div className="text-form-label my-auto">Password</div>
          <div className="flex form-input md:col-span-2">
            <input
              type={showPassword ? "text" : "password"}
              className="w-full outline-none md:col-span-2"
              placeholder="Enter your password"
              {...register("password", {
                required: "Password is required.",
              })}
            />
            <div
              className="w-6 h-6 color-brand-yellow"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          <button
            type="button"
            className="button-yellow md:col-span-3"
            onClick={() => login()}
          >
            Login
          </button>

        </div>
      </div>
    }
  }

  return (
    <>
      <div className="text-form-title mb-6">Checkout Details</div>
      {UserSession.checkIsLoggedIn()
        ? buildLoggedInView()
        : buildNotLoggedInView()}
    </>
  );
};
