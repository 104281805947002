import { default as MembershipFAQ } from "@Assets/membership/membership-faq.png";
import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import { FC, useState } from "react";

export type FAQItemsModel = {
  question: string;
  answer: string;
};

export type FAQLeftProps = {
  faqList: FAQItemsModel[];
};

export type FAQRightProps = {
  email: string;
};

export type FAQProps = {
  data: FAQItemsModel[];
  emailData: FAQRightProps;
};

export type FAQItemsProps = FAQItemsModel & {
  isOpen: boolean;
  index: number;
  onCollapse: (index: number) => void;
};

export const FAQItems: FC<FAQItemsProps> = ({
  question,
  answer,
  isOpen,
  index,
  onCollapse,
}) => {
  return (
    <div
      className="bg-white border rounded-lg p-6 flex justify-between mb-3"
      onClick={() => onCollapse(index)}
    >
      <div>
        <div className="text-[22px] font-bold">{question}</div>
        {isOpen && (
          <div
            className="color-midas-neutral-900 font-normal mt-4"
            dangerouslySetInnerHTML={{
              __html: answer.replaceAll("\n", "<br />"),
            }}
          />
        )}
      </div>
      <div className="text-xl color-midas-neutral-500 font-bold">
        {isOpen ? "-" : "+"}
      </div>
    </div>
  );
};

export const FAQLeftSections: FC<FAQLeftProps> = ({ faqList }) => {
  const [openedIndex, setOpenedIndex] = useState<number>(-1);

  const onCollapse = (index: number) => {
    if (index === openedIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(index);
    }
  };

  return (
    <div>
      {faqList.map((item, index) => {
        return (
          <FAQItems
            question={item.question}
            answer={item.answer}
            isOpen={index === openedIndex}
            key={`FAQ-${index}`}
            index={index}
            onCollapse={onCollapse}
          />
        );
      })}
    </div>
  );
};

export const FAQRightSections: FC<FAQRightProps> = ({ email }) => {
  return (
    <div className="bg-white border rounded-lg p-6 w-full">
      <img
        className="mx-auto"
        src={MembershipFAQ}
        alt=""
        width={72}
        height={72}
      />
      <div className="text-lg md:text-xl font-extrabold text-center mt-6">
        Ada pertanyaan dan ingin konsultasi?
      </div>
      <div className="flex justify-center">
        <MidasRoundedYellowButton
          label="Konsultasi ke Admin"
          additionalClass="mt-14"
          urlDestination={`https://api.whatsapp.com/send?phone=6287884792402&text=Saya%20dari%20%20web%20midas%2C%20ingin%20mengetahui%20program%20midas%20stocks%20membership%20lebih%20lanjut%20🙏🏻`}
          isExternalLink={true}
        />
      </div>
    </div>
  );
};

export const FAQSections: FC<FAQProps> = ({ data, emailData }) => {
  return (
    <div className="mt-2 md:mt-16 mx-7 md:mx-16">
      <div className="text-white font-bold text-5xl text-left">
        Frequently <div className="color-brand-yellow">Asked Questions</div>
      </div>
      <div className="mt-7 md:mt-14 grid grid-cols-1 gap-4 md:grid-cols-3">
        <div className="col-span-2">
          <FAQLeftSections faqList={data} />
        </div>
        <div className="">
          <FAQRightSections email={emailData.email} />
        </div>
      </div>
    </div>
  );
};
