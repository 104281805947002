import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const UploadProfilePictUsecase: (
  formData: FormData
) => Promise<boolean> = async (formData) => {
  try {
    await client.post(ApiConst.API_CHANGE_PICT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
