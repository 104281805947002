import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const ApplyVoucherUsecase: (
  voucherCode: string,
  orderId: string
) => Promise<boolean> = async (voucherCode, orderId) => {
  try {
    let formData = new FormData();
    formData.append("voucher_code", voucherCode);
    formData.append("order_id", orderId);

    await client.post(ApiConst.API_APPLY_VOUCHER, formData);
    return true;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
