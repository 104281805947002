import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const ResetPasswordUsecase: (
  email: string,
  newPassword: string,
  newPasswordConfirmation: string,
  token: string
) => Promise<string> = async (
  email,
  newPassword,
  newPasswordConfirmation,
  token
) => {
  try {
    let response = await client.post(ApiConst.API_RESET_PASSWORD, {
      email: email,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
      token: token,
    });
    return response.data.message;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
