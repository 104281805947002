import {
  MidasVideoPlayer,
  MidasVideoPlayerProps,
} from "@Libraries/components/video-player/VideoPlayer.component";

export class VideoModel {
  componentKey: string;
  videoUrl: string;
  widthPercentage: number;
  showVideoControls: boolean;
  isAutoPlay: boolean;
  canDownload: boolean;
  videoSource: string;

  constructor(
    componentKey: string,
    videoUrl?: string,
    widthPercentage?: number,
    showVideoControls?: boolean,
    isAutoPlay?: boolean,
    canDownload?: boolean,
    videoSource?: string
  ) {
    this.componentKey = componentKey;
    this.videoUrl = videoUrl ?? "";
    this.widthPercentage = widthPercentage ?? 100;
    this.showVideoControls = showVideoControls ?? true;
    this.isAutoPlay = isAutoPlay ?? false;
    this.canDownload = canDownload ?? false;
    this.videoSource = videoSource ?? "url";
  }

  render() {
    return (
      <MidasVideoPlayer
        componentKey={this.componentKey}
        videoUrl={this.videoUrl}
        options={{
          widthPercentage: this.widthPercentage,
          showVideoControls: this.showVideoControls,
          isAutoPlay: this.isAutoPlay,
          canDownload: this.canDownload,
          videoSource: this.videoSource,
        }}
      />
    );
  }

  getJsonString() {
    return {
      type: "video",
      videoUrl: this.videoUrl,
      options: {
        widthPercentage: this.widthPercentage,
        showVideoControls: this.showVideoControls,
        isAutoPlay: this.isAutoPlay,
        canDownload: this.canDownload,
        videoSource: this.videoSource,
      },
    };
  }

  jsonToModel(data: MidasVideoPlayerProps) {
    this.videoUrl = data.videoUrl;
    this.widthPercentage = data.options.widthPercentage;
    this.showVideoControls = data.options.showVideoControls;
    this.isAutoPlay = data.options.isAutoPlay;
    this.canDownload = data.options.canDownload;
    this.videoSource = data.options.videoSource;
  }
}
