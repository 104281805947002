import { default as CNBC } from "@Assets/homepage/media-cnbc.png";
import { default as iNews } from "@Assets/homepage/media-inews.png";
import { default as OkeFinance } from "@Assets/homepage/media-oke-finance.png";
import { default as SindoNews } from "@Assets/homepage/media-sindo-news.png";
import { default as ManyMore } from "@Assets/homepage/media-many-more.png";

export const MediaSection = () => {
  return (
    <div className="flex flex-row pt-20 justify-center gap-4 flex-wrap mx-7 md:mx-16">
      <div className="text-white font-bold responsive-header-title mx-10 md:mx-16 text-center">
          Dipercaya dan diliput <span className="color-brand-yellow">oleh berbagai media</span>
      </div>
      <div className="flex justify-center gap-4 flex-wrap mx-7 md:mx-16">
        <img src={CNBC} className="object-cover max-w-xs max-h-52" alt="CNBC" />
        <img src={iNews} className="object-cover max-w-xs max-h-52" alt="iNews" />
        <img src={OkeFinance} className="object-cover max-w-xs max-h-52" alt="OkeFinance" />
        <img src={SindoNews} className="object-cover max-w-xs max-h-52" alt="SindoNews" />
        <img src={ManyMore} className="object-cover max-w-xs max-h-52" alt="ManyMore" />
      </div>
    </div>
  );
};
