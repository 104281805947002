import { MidasVideoPlayer } from "@Libraries/components/video-player/VideoPlayer.component";
import { Helmet } from "react-helmet";

export const StartingGuideSection = () => {
  return (
    <div className="mt-4">
      <Helmet encodeSpecialCharacters={true}>
        <title>Starting Guide - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Starting Guide" />
      </Helmet>
      <div className="text-left color-brand-yellow font-extrabold text-md">
        (NEW) MIDAS Watchlist Sudah Tersedia
      </div>
      <div className="text-left color-brand-yellow text-sm mb-4">
        {`Klik Home -> Watchlist`}
      </div>
      <div className={`mx-auto ${window.screen.width <= 500 ? "w-full" : "w-2/3"}`}>
        <MidasVideoPlayer
          componentKey="member-starting-guide-video"
          videoUrl="https://youtu.be/PanAEImHnuE"
          options={{
            widthPercentage: window.screen.width <= 500 ? 90 : 75,
            showVideoControls: true,
            isAutoPlay: false,
            canDownload: false,
            videoSource: "youtube",
          }}
        />
      </div>

      <div className="mt-4 rounded-lg bg-midas-neutral-500 p-4">
        <p className="text-lg font-bold">Welcome!</p>
        <div className="border-t border-brand-yellow w-full"></div>
        <p className="mt-4 text-left">
          Selamat datang di MIDAS Stock Membership, sebelumnya kami Founder dan
          Co-Founder MIDAS mengucapkan terima kasih telah memilih MIDAS sebagai
          partner investasi anda.
        </p>
        <p className="mt-4 text-left">
          Goal dari Membership ini adalah membantu anda menjadi investor yang
          Pro, Independent, dan Confident.
        </p>
        <p className="mt-4 text-left">
          Yang secara mindset, prinsip, kokoh dan tidak goyah dengan kondisi
          market bagaimanapun, memiliki pemahaman yang mendalam dari segi
          keilmuan, dan yang pasti konsisten mencetak cuan signifikan secara
          terus-menerus untuk mencapai hal tersebut, tidak ada proses yang
          instan, diharapkan para member bisa serius dan mengikuti step demi
          step yang sudah disiapkan, agar program membership ini worth every
          penny yang sudah anda keluarkan 🙂
        </p>

        <p className="text-lg font-bold mt-6">Starting Tips</p>
        <div className="border-t border-brand-yellow w-full"></div>
        <p className="mt-4 text-left">
          Untuk anda yang baru bergabung, agar tidak bingung dan overwhelming
          dengan fitur-fitur yang ada di member area, kami akan membantu anda
          untuk fokus pada hal yang paling utama dulu:
        </p>
        <ul className="text-left px-4">
          <li className="list-decimal">
            Mulai dengan menonton{" "}
            <i>“video yang ada di dashboard utama member area”</i>, video
            tersebut adalah video untuk memperkenalkan anda dengan fitur-fitur
            yang ada di membership
          </li>
          <li className="mt-4 list-decimal">
            Jika sudah alihkan fokus ke menu <i>“Online Course”</i> karena kunci
            dari menguasai fitur-fitur lain yang ada di member area seperti;
            Midas Jarvis, Tools, Framework dll. Adalah dengan menonton online
            course karena di online course sudah terdapat penjelasan dan
            tutorial
          </li>
          <li className="mt-4 list-decimal">
            Make sure jangan lupa untuk join Grup Telegram khusus member dengan
            cara; Login Member Area, pilih menu “Grup Diskusi” Klik “Join Grup”
            di kedua tombol yang tersedia
          </li>
        </ul>

        <p className="mt-6 text-left">
          Jika ada pertanyaan dan kendala, silahkan tanyakan kepada kami via WA,
          Telegram, atau bisa via Grup Telegram Member
        </p>
        <p className="mt-10 text-left">Have a great investment journey,</p>
        <p className="font-bold">Midas Team.</p>
      </div>
    </div>
  );
};
