/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { EMAIL_REGEX } from "@Libraries/utils/Regex";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordUsecase } from "../domain/ForgotPassword.usecase";

type ForgotPasswordFormInputs = {
  email: string;
};

export const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>();

  useEffect(() => {
    if (UserSession.checkIsLoggedIn()) {
      navigate("/");
    }
  }, []);

  const onSubmit = async (data: ForgotPasswordFormInputs) => {
    try {
      setSuccessMessage(await ForgotPasswordUsecase(data.email));
      setErrorMessage("");
    } catch (error: any) {
      setErrorMessage(error.message);
      setSuccessMessage("");
    }
  };

  return (
    <div className="bg-midas-neutral-900 h-screen overflow-scroll">
      <HeaderWithMenu
        shouldShowLoginButton={false}
        menuItems={getSiteMenus()}
      />
      <div className="h-full flex justify-evenly">
        <div className="w-full h-full hidden lg:block text-white"></div>
        <div className="w-full h-full text-white">
          <form
            className="m-6 w-fit mx-auto lg:my-auto lg:mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mt-8 font-bold text-3xl">Lupa Password</div>

            {/* error message */}
            {successMessage !== "" && (
              <div className="w-full mt-6 rounded p-2 text-white text-xs bg-green-500 text-justify">
                {successMessage}
              </div>
            )}

            {/* error message */}
            {errorMessage !== "" && (
              <div className="w-full mt-6 rounded p-2 text-white text-xs bg-red-500 text-justify">
                {errorMessage}
              </div>
            )}

            <div className="mt-8 flex flex-col">
              <label className="font-normal" htmlFor="login-email">
                Email
              </label>
              <input
                type="text"
                className="input-form"
                id="login-email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid email address.",
                  },
                })}
              />
              {errors.email && (
                <p className="text-red-500 text-xs">{errors.email.message}</p>
              )}
            </div>

            <button className="button-yellow mt-8 w-full">Kirim Email</button>
          </form>
        </div>
      </div>
    </div>
  );
};
