import { FC } from "react";
import { MidasRoundedYellowButton } from "../buttons/MidasRoundedYellowButton.component";
import { TextWithImageProps } from "./types/TextWithImage.type";

export const TextWithLeftImageComponent: FC<TextWithImageProps> = ({
  textChildren,
  image,
  onClick,
  showButton,
  buttonLabel,
}) => {
  return (
    <div className="mx-9 md:mx-24 mt-16 grid grid-cols-1 gap-12 md:gap-20 md:grid-cols-2 text-left">
      <div className="mx-auto my-auto w-full">{image}</div>
      <div>
        {textChildren}
        {showButton === true && buttonLabel && onClick && (
          <MidasRoundedYellowButton
            additionalClass="mt-8 rounded-full"
            label={buttonLabel}
            onClick={onClick}
            isExternalLink={false}
          />
        )}
      </div>
    </div>
  );
};
