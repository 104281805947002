export type IFrameProps = {
  url: string;
  pageId: string;
};

export class IFrameModel {
  componentKey: string;
  url: string;
  pageId: string;
  title: string;

  constructor(
    componentKey: string,
    url: string = "",
    pageId: string = "",
    title: string = ""
  ) {
    this.componentKey = componentKey;
    this.url = url;
    this.pageId = pageId;
    this.title = title;
  }

  render() {
    return (
      <iframe
        src={this.url}
        className="w-screen h-screen overflow-auto"
        title={this.title}
      ></iframe>
    );
  }

  getJsonString() {
    return {
      type: "iframe",
      url: this.url,
      pageId: this.pageId,
    };
  }

  jsonToModel(data: IFrameProps) {
    this.url = data.url;
    this.pageId = data.pageId;
  }
}
