import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const ChangePasswordUsecase: (
  formData: FormData
) => Promise<boolean> = async (formData) => {
  try {
    await client.put(ApiConst.API_CHANGE_PASSWORD, formData);
    return true;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
