import { CourseModel } from "@Features/dashboard/data/course.model";
import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const GetCourseDetailUsecase: (
  slug: string
) => Promise<CourseModel> = async (slug) => {
  try {
    let response = await client.get(ApiConst.API_COURSE + "/" + slug);
    let data: CourseModel = { ...response.data.data };

    let durationInSeconds = 0;
    let videoCount = 0;

    data.sections.forEach((section) => {
      videoCount += section.items.length;
      section.items.forEach((course) => {
        durationInSeconds += parseInt(course.duration_in_seconds);
      });
    });

    let durationHours = Math.floor(durationInSeconds / (60 * 60));
    durationInSeconds -= durationHours * 60 * 60;
    let durationMinutes = Math.floor(durationInSeconds / 60);
    durationInSeconds -= durationMinutes * 60;

    let durationString = `${
      durationHours > 10 ? durationHours : `0${durationHours}`
    }:${durationMinutes > 10 ? durationMinutes : `0${durationMinutes}`}:${
      durationInSeconds > 10 ? durationInSeconds : `0${durationInSeconds}`
    }`;

    data.part = data.sections.length.toString();
    data.duration = durationString;
    data.videoCount = videoCount.toString();

    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
