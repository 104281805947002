import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import {
  GENERAL_SELECTED_PRODUCTS,
  GENERAL_TESTIMONI,
  HOMEPAGE_PORTFOLIO,
  HOMEPAGE_SHOWCASE,
} from "@Libraries/utils/Const";

export const FetchHomepageSettingsUsecase = async () => {
  let optionKeys = `${HOMEPAGE_PORTFOLIO};${HOMEPAGE_SHOWCASE};${GENERAL_SELECTED_PRODUCTS};${GENERAL_TESTIMONI}`;
  return await LoadPageSettingUsecase(optionKeys);
};
