import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { StockModel } from "../data/stock.model";

export const GetStockListUsecase: () => Promise<StockModel[]> = async () => {
  try {
    let response = await client.get(ApiConst.API_JARVIS);

    let data = [...response.data];
    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
