import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";

export const TrialPortoActive = () => {
  return (
    <div className="mt-4 w-[80%] h-full">
      <div className="pt-1 pb-3 text-left">
        <div className="text-white text-xl font-bold">
          Selamat, Anda mendapatkan free bonus fitur : <br />
          - Online Course dan beberapa Analisa Saham selama 7 hari, <br />
          jika Anda tertarik untuk upgrade ke Midas Full-Experience (Membership + Portofolio) mohon contact Admin untuk mendapatkan harga khusus.
        </div>
        <MidasRoundedYellowButton label="Upgrade Akun" isExternalLink={true} urlDestination="https://wa.link/v0heg3" additionalClass="mt-4 py-3 px-4 font-bold text-xl" />
      </div>
    </div>
  );
};

export const TrialCourseActive = () => {
  return (
    <div className="mt-4 w-[80%] h-full">
      <div className="pt-1 pb-3 text-left">
        <div className="text-white text-xl font-bold">
          Selamat, Anda mendapatkan free bonus fitur : <br />
          - Stock Treasure Map (bagian tools) dan MIDAS Jarvis selama 1 bulan, <br />
          jika Anda tertarik untuk upgrade ke Midas Full-Experience mohon contact Admin untuk mendapatkan harga khusus.
        </div>
        <MidasRoundedYellowButton label="Upgrade Akun" isExternalLink={true} urlDestination="https://wa.link/c4z5ae" additionalClass="mt-4 py-3 px-4 font-bold text-xl" />
      </div>
    </div>
  );
};

export const TrialCourseAndPortofolioActive = () => {
  return (
    <div className="mt-4 w-[80%] h-full">
      <div className="pt-1 pb-3 text-left">
        <div className="text-white text-xl font-bold">
          Selamat, Anda mendapatkan free bonus fitur : <br />
          - Stock Treasure Map (bagian tools) dan MIDAS Jarvis selama 1 bulan,<br />
          - Online Course dan beberapa Analisa Saham selama 7 hari,<br />
          jika Anda tertarik untuk upgrade ke Midas Full-Experience mohon contact Admin untuk mendapatkan harga khusus.
        </div>
        <MidasRoundedYellowButton label="Upgrade Akun" isExternalLink={true} urlDestination="https://wa.link/v0heg3" additionalClass="mt-4 py-3 px-4 font-bold text-xl" />
      </div>
    </div>
  );
};