import {
  MidasSpacer,
  SpacerProps,
} from "../components/widgets/Spacer.component";

export class SpacerModel {
  componentKey: string;
  height: number;
  width: number;
  widthPercentage: number;

  constructor(
    componentKey: string,
    height = 100,
    width = 20,
    widthPercentage = 0
  ) {
    this.componentKey = componentKey;
    this.height = height;
    this.width = width;
    this.widthPercentage = widthPercentage;
  }

  render() {
    return (
      <MidasSpacer
        componentKey={this.componentKey}
        options={{
          height: this.height,
          width: this.width,
          widthPercentage: this.widthPercentage,
        }}
      />
    );
  }

  getJsonString() {
    return {
      type: "spacer",
      options: {
        height: this.height,
        width: this.width,
        widthPercentage: this.widthPercentage,
      },
    };
  }

  jsonToModel(data: SpacerProps) {
    this.height = data.options.height;
    this.width = data.options.width;
    this.widthPercentage = data.options.widthPercentage;
  }
}
